import axios from "axios";
import Cookies from 'js-cookie';
import { io } from 'socket.io-client';
import { getLanguage } from './i18n';

const baseURL = process.env.REACT_APP_API_URL || (process.env.NODE_ENV === "development" ? "http://localhost:3001" : "https://app.labia.pro/api");
const socketURL = baseURL.replace('/api', '');
const socket = io(socketURL, {
    autoConnect: false,
    withCredentials: true,
    path: process.env.NODE_ENV === "development" ? "/socket" : "/api/socket"
  });
const ERROR = {
    PIPELINE: 'PIPELINE',
    PIPELINE_STAGES: 'PIPELINE_STAGES',
    COMPANY: 'COMPANY',
    CONTACT: 'CONTACT',
    TASK: 'TASK',
    MEETING: 'MEETING',
    DEAL: 'DEAL',
    MAIL: 'MAIL',
    NOTE: 'NOTE',
    CALL: 'CALL',
    UNKNOWN: 'UNKNOWN'
}

//axios.defaults.headers.common['sessionId'] = Cookies.get('token');
axios.defaults.withCredentials = true;

function CreateDealFromJson(json) {
    return new Promise((resolve, reject) => {
        var data = JSON.parse(JSON.stringify(json));
        // Clean up the data object
        if (data.deal) {
            if (data.deal.pipeline) {
                // Keep only id and stage info
                data.deal.pipeline = {
                    id: data.deal.pipeline.id,
                    stage: data.deal.pipeline.stage ? {
                        id: data.deal.pipeline.stage.id
                    } : undefined
                };
            }
        }

        // Clean up note associations
        if (data.note) {
            if (data.note.contacts) {
                data.note.contacts = data.note.contacts.map(contact => ({
                    id: contact.id,
                    firstName: contact.firstName,
                    lastName: contact.lastName
                }));
            }
            if (data.note.companies) {
                data.note.companies = data.note.companies.map(company => ({
                    id: company.id,
                    name: company.name
                }));
            }
            if (data.note.deals) {
                data.note.deals = data.note.deals.map(deal => ({
                    id: deal.id
                }));
            }
        }

        // Clean up meeting attendees
        if (data.meeting && data.meeting.attendees) {
            data.meeting.attendees = data.meeting.attendees.map(attendee => ({
                id: attendee.id,
                email: attendee.email,
                firstName: attendee.firstName,
                lastName: attendee.lastName
            }));
        }

        // Clean up mail recipients 
        if (data.mail && data.mail.recipient) {
            data.mail.recipient = data.mail.recipient.map(recipient => ({
                id: recipient.id,
                firstName: recipient.firstName,
                lastName: recipient.lastName,
                email: recipient.email // Keep email for sending
            }));
        }

        // Remove any options arrays
        Object.keys(data).forEach(key => {
            if (data[key]) {
                delete data[key].options;
                delete data[key].companiesOptions;
                delete data[key].contactsOptions; 
                delete data[key].dealsOptions;
            }
        });

        console.log(JSON.stringify(data));
        axios.post(`${baseURL}/createDeal`, data, { headers: { 'language': getLanguage() } })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                console.log(response.response);
                if (response.code == "ERR_BAD_REQUEST" && response.status != 401) {
                    processError(response.response.data, reject);
                } else {
                    reject(response.response)
                }
            });
    });
}


function CreateJSONFromText(text, updateProgress, resolve, reject) {
    if (socket.connected) {
        socket.emit('text', { data: { text: text } });
    } else {
        socket.connect();
        socket.on('connect', () => {
            console.log('connected');
            socket.emit('text', { data: { text: text } });
        });
    }
    socket.on('disconnect', () => {
        console.log('disconnected');
    });
    socket.on('progress', (progress) => {
        console.log(progress);
        updateProgress(progress);
    });
    socket.on('text', (response) => {
        resolve(response);
        socket.disconnect();
        socket.close();
    });
    socket.on('actions', (actions) => {
        
    });

    socket.on('error', (error) => {
        socket.disconnect();
        socket.close();
        console.log(error);
        if (error.code == "ERR_BAD_REQUEST" && error.status != 401) {
            processError(error.response.data, reject);
        } else {
            reject(error.response)
        }
    });
}


function UploadAudio(audio, updateProgress) {
    return new Promise((resolve, reject) => {
        var bodyFormData = new FormData();
        bodyFormData.append('audio', audio, `${makeid(5)}.webm`);
        bodyFormData.append('language', getLanguage());
        axios({
            method: "post",
            url: `${baseURL}/audio`,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: updateProgress
          })
            .then(function (response) {
                console.log(response);
                resolve(response.data);
            })
            .catch(function (response) {
                console.log(response.response);
                if (response.code == "ERR_BAD_REQUEST" && response.response.status != 401) {
                    processError(response.response.data, reject);
                } else {
                    reject(response.response)
                }
            });
    });
}

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

function processError(error, reject) {
    if (error.message) {
        let errorType = error.message.toUpperCase()
        let originalJson = error.originalJson;
        switch (errorType) {
            case 'PIPELINE':
                reject({error: ERROR.PIPELINE, originalJson: originalJson, options: error.options})
                break;
            case 'COMPANY':
                reject({error: ERROR.COMPANY, originalJson: originalJson, options: error.options})
                break;
            case 'CONTACT':
                reject({error: ERROR.CONTACT, originalJson: originalJson, options: error.options})
                break;
            case 'TASK':
                reject({error: ERROR.TASK, originalJson: originalJson, options: error.options})
                break;
            case 'MEETING':
                reject({error: ERROR.MEETING, originalJson: originalJson, options: error.options})
                break;
            case 'DEAL':
                reject({error: ERROR.DEAL, originalJson: originalJson, options: error.options})
                break;
            case 'EMAIL':
                reject({error: ERROR.MAIL, originalJson: originalJson, options: error.options})
                break;
            case 'NOTE':
                reject({error: ERROR.NOTE, originalJson: originalJson, options: error.options})
                break;
            case 'CALL':
                reject({error: ERROR.CALL, originalJson: originalJson, options: error.options})
                break;
            default:
                reject({error: ERROR.UNKNOWN, originalJson: originalJson, options: error.options})
        }
    }
}

function fetchCompanies({
    value,
    after,
    createdate,
    noCache,
    signal
}) {
    return new Promise((resolve, reject) => {
        var url = `${baseURL}/companies?`;
        if (value) {
            url += `companyName=${value}`;
        }
        if (noCache) {
            url += `&noCache=${noCache}`;
        }
        if (after) {
            url += `&after=${after}`;
        }
        if (createdate) {
            url += `&createdate=${createdate}`;
        }
        axios.get(url, { signal: signal })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

function fetchDeals(value, after, signal) {
    return new Promise((resolve, reject) => {
        var url = `${baseURL}/deals`;
        if (value) {
            url += `?deal=${value}`;
        }
        if (after) {
            url += `&after=${after}`;
        }
        axios.get(url, { signal: signal })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

function fetchDeal(id) {
    return new Promise((resolve, reject) => {
        axios.get(`${baseURL}/deal/${id}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

function fetchDealsGrouped({value, after, closedate, noCache, signal}) {
    return new Promise((resolve, reject) => {
        var url = `${baseURL}/dealsGrouped?`;
        if (value) {
            url += `deal=${value}`;
        }
        if (after) {
            url += `&after=${after}`;
        }
        if (closedate) {
            url += `&closedate=${closedate}`;
        }
        if (noCache) {
            url += `&noCache=${noCache}`;
        }
        axios.get(url, { signal: signal })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}


function fetchContacts({firstName, after, createdate, noCache, signal}) {
    return new Promise((resolve, reject) => {
        var url = `${baseURL}/contacts?`;
        if (firstName) {
            url += `firstName=${firstName}`;
        }
        if (after) {
            url += `&after=${after}`;
        }
        if (createdate) {
            url += `&createdate=${createdate}`;
        }
        if (noCache) {
            url += `&noCache=${noCache}`;
        }
        axios.get(url, {signal: signal})
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

function searchContacts(firstName, page, signal) {
    return new Promise((resolve, reject) => {
        var url = `${baseURL}/searchcontacts`;
        if (firstName) {
            url += `?firstName=${firstName}`;
        }
        if (page) {
            url += `&page=${page}`;
        }
        axios.get(url, {signal: signal})
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}


function getUserData() {
    return new Promise((resolve, reject) => {
        axios.get(`${baseURL}/user`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

function fetchPipelines() {
    return new Promise((resolve, reject) => {
        axios.get(`${baseURL}/pipelines`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

function logout() {
    return new Promise((resolve, reject) => {
        axios.post(`${baseURL}/logout`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

function createContact(data) {
    return new Promise((resolve, reject) => {
        axios.post(`${baseURL}/contacts`, data)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

function createCompany(data) {
    return new Promise((resolve, reject) => {
        axios.post(`${baseURL}/companies`, data)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

function disconnectGmail() {
    return new Promise((resolve, reject) => {
        axios.post(`${baseURL}/gmail/disconnect`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

function fetchProperties(objectType, force = false, all = false) {
    return new Promise((resolve, reject) => {
        axios.get(`${baseURL}/properties/${objectType}?force=${force}&all=${all}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

function fetchDomainProperties() {
    return new Promise((resolve, reject) => {
        axios.get(`${baseURL}/domain-properties`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

function updateProperties(objectType, properties) {
    return new Promise((resolve, reject) => {
        axios.post(`${baseURL}/properties/${objectType}`, properties)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

function fetchTasks(query, createdate = "ASCENDING", status = "NOT_STARTED", signal) {
    return new Promise((resolve, reject) => {
        axios.get(`${baseURL}/tasks?query=${query}&createdate=${createdate}&status=${status}`, {signal: signal})
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

function fetchTask(id) {
    return new Promise((resolve, reject) => {
        axios.get(`${baseURL}/task/${id}`)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

function updateTask(id, task) {
    return new Promise((resolve, reject) => {
        axios.post(`${baseURL}/task/${id}`, task)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (response) {
                reject(response)
            });
    });
}

export default {
    baseURL,
    ERROR,
    CreateDealFromJson,
    CreateJSONFromText,
    UploadAudio,
    fetchCompanies,
    fetchContacts,
    fetchPipelines,
    searchContacts,
    getUserData,
    createCompany,
    createContact,
    fetchDeals,
    logout,
    processError,
    fetchDealsGrouped,
    fetchDeal,
    disconnectGmail,
    fetchProperties,
    fetchDomainProperties,
    socket,
    updateProperties,
    fetchTasks,
    fetchTask,
    updateTask
}