import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      es: {
        translation: {
          noCreditsLeft: "No tienes créditos disponibles",
          login: "Iniciar sesión",
          sendingData: "Enviando datos",
          notObjectProcessed: "No se han podido procesar los siguientes objetos:\n",
          company: "Compañía",
          contact: "Contacto",
          deal: "Negocio",
          task: "Tarea",
          meeting: "Reunión",
          mail: "Correo",
          contacts: "Contactos",
          note: "Nota",
          call: "Llamada",
          pipeline: "Pipeline",
          pipelineStages: "Etapas",
          other: "Otro",
          selectPipeline: "Seleccione un pipeline",
          selectPipelineStage: "Seleccione una etapa",
          selectCompany: "Valide los datos de la compañía",
          selectContact: "Valide los datos del contacto",
          selectTask: "Valide los datos de la tarea",
          selectMeeting: "Valide los datos de la reunión",
          selectMail: "Valide los datos del correo",
          selectNote: "Valide los datos de la nota",
          selectCall: "Valide los datos de la llamada",
          selectDeal: "Valide los datos del negocio",
          tasks: "Tareas",
          next: "Siguiente",
          finish: "Finalizar",
          cancel: "Cancelar",
          cancelConfirmation: "¿Estás seguro de que quieres cancelar?",
          close: "Cerrar",
          ok: "Ok",
          errorProcessingRequest: "Error al procesar la solicitud",
          errorProcessingRequestDescription: "Inténtelo de nuevo.",
          processingAudio: "Procesando audio",
          minutesSaved: "Has ahorrado",
          minutes: "minutos",
          savedIn: "en",
          audioSentCorrectly: "Audio enviado correctamente",
          retry: "Reintentar",
          dateTime: "Fecha y hora",
          duration: "Duración",
          disposition: "Resultado",
          busy: "Ocupado",
          connected: "Conectado",
          noResponse: "No respondió",
          wrongNumber: "Número incorrecto",
          voiceMessage: "Dejó mensaje de voz",
          liveMessage: "Dejó mensaje en vivo",
          direction: "Dirección",
          inbound: "ENTRANTE",
          outbound: "SALIENTE",
          title: "Título",
          audioText: "Pulsa para grabar",
          description: "Descripción",
          associations: "Asociaciones",
          companies: "Empresas",
          deals: "Negocios",
          companyName: "Nombre de la compañía",
          companyDomain: "Dominio de la compañía",
          selectCompany: "Selecciona una compañía",
          createCompany: "Crear compañía",
          errorSendingAudio: "Error al enviar el audio",
          createCompanyDescription: "Crea una nueva compañía",
          userAlreadyExists: "Sucedió un error, por favor compruebe los datos y vuelva a intentarlo",
          name: "Nombre",
          lastName: "Apellidos",
          email: "Email",
          phoneNumber: "Teléfono",
          selectContact: "Selecciona un contacto",
          createContact: "Crear contacto",
          dealName: "Nombre del negocio",
          dealValue: "Valor del negocio",
          dealClose: "Fecha de cierre del negocio",
          selectDeal: "Selecciona un negocio",
          recipients: "Destinatarios",
          subject: "Asunto",
          message: "Mensaje",
          meetingTitle: "Título de la reunión",
          meetingStartDate: "Fecha de inicio",
          meetingEndDate: "Fecha de finalización",
          meetingLocation: "Lugar",
          meetingReminder: "Recordatorio",
          meetingDescription: "Descripción",
          meetingInternalNote: "Nota interna",
          low: "Baja",
          medium: "Media",
          high: "Alta",
          dueDate: "Fecha de vencimiento",
          hsTaskType: "Tipo de tarea",
          call: "Llamada",
          email: "Correo electrónico",
          todo: "Tarea",
          crmUpdated: "CRM actualizado correctamente",
          domain: "Dominio",
          phone: "Teléfono",
          address: "Dirección",
          audioCompanyText: "Pulsa para grabar acciones sobre esta compañía",
          errorMovingDeal: "Error al mover el negocio. Por favor, inténtalo de nuevo más tarde.",
          selectStage: "Selecciona a que etapa del pipeline quieres pasar este negocio.",
          moveDeal: "Mover a otra etapa",
          save: "Guardar",
          stage: "Etapa",
          amount: "Importe",
          closedate: "Fecha de cierre",
          createdate: "Fecha de creación",
          audioContactText: "Pulsa para grabar acciones sobre este contacto",
          record: "Grabar",
          account: "Cuenta",
          recording: "Grabando audio...",
          paused: "Pausado",
          microphone: "Micrófono",
          recordingCancelled: "Grabación cancelada",
          search: "Buscar",
          min: "min",
          sortBy: "Ordenar por",
          newest: "Más nuevo",
          oldest: "Más antiguo",
          disconnectGmail: "Desconectar Gmail",
          logout: "Cerrar sesión",
          connectGmail: "Conectar Gmail",
          gmailConnected: "Gmail conectado correctamente",
          gmailDisconnected: "Gmail desconectado correctamente",
          attendees: "Asistentes",
          properties: "Propiedades",
          select_properties: "Seleccione propiedades",
          propertyToUpdate: "Propiedad a actualizar",
          selectProperty: "Seleccione propiedades",
          updateProperties: "Actualizar propiedades",
          propertiesUpdated: "Propiedades actualizadas correctamente",
          propertiesUpdatedError: "Error al actualizar las propiedades",
          createGoogleMeet: "Enviar enlace de Google Meet",
          createMeeting: "Enviar convocatoria de reunión al cliente",
          completed: "Completado",
          not_started: "No iniciado",
          none: "Sin prioridad",
          reminders: "Recordatorios",
          nearest: "Más próximo",
          farthest: "Más lejano",
          updateTask: "Actualizar tarea",
          status: "Estado",
          notStarted: "No iniciado",
          inProgress: "En progreso",
          errorUpdatingTask: "Error al actualizar la tarea",
          priority: "Prioridad",
          type: "Tipo",
          identifyingActions: "Identificando acciones",
          welcomeToLabia: "Bienvenido a Labia",
          onboardingDescription: "Descubre lo que puedes hacer con Labia a través de estos ejemplos. ¡Haz clic en 'Pruébalo' para ver cómo funciona!",
          example: "Ejemplo",
          tryIt: "Pruébalo",
          showMore: "Mostrar más",
          showLess: "Mostrar menos",
          learnToUseLabia: "Pincha aquí",
          onboardingExamples: {
            contactAndCompany: {
              title: "Crear Contactos y Empresas",
              description: "Crea fácilmente nuevos contactos y empresas con todos sus detalles en lenguaje natural.",
              example: "Labia, creame un contacto llamado Daniel Rodriguez de la empresa Labia con el email dani@labia.pro y el teléfono 678368027. Crea la compañía Labia con el dominio labia.pro",
            },
            dealsPipeline: {
              title: "Crear Negocios en el pipeline de ventas",
              description: "Crea negocios en el pipeline de ventas para hacer seguimiento de la información importante.",
              example: "Crea un negocio por valor de 5000 euros con el nombre \"Paquete 100 unidades\" y una fecha de cierre estimada para el 20 de junio.",
            },
            tasksAndNotes: {
              title: "Crear Tareas y Notas",
              description: "Programa tareas y añade notas para hacer seguimiento de información importante y cerrar más ventas.",
              example: "Busca al contacto Daniel Rodríguez y crea una tarea para llamarle el viernes a las 10 de la mañana para comprobar si ha podido ver nuestro catálogo y nuestra oferta. Crea además una nota interna exponiendo que es el director de compras, es celíaco  y le gusta el Real Madrid."
            },
            email: {
              title: "Enviar emails",
              description: "Envía Emails personalizados y con el tono de tu empresa",
              example: "Envíale un email a Daniel Rodríguez con tono emocionado diciendo que ya he probado Labia, me ha parecido increíble y me ahorrará mucho tiempo, añádele iconos."
            },
            meeting: {
              title: "Agenda reuniones",
              description: "Agenda reuniones con clientes y enviales la convocatoria de la reunión",
              example: "Agenda una reunión con Daniel Rodríguez para el 15 de junio a las 12  en sus oficinas de Madrid con el Asunto: “Reunión Demo”, donde hablaremos sobre nuestro nuevo producto."
            },
            call: {
              title: "Llamadas",
              description: "Registra llamadas con el resumen de lo conversado con el cliente",
              example: "Registra una llamada con el contacto Daniel Rodríguez hace 10 minutos donde hemos hablado sobre el presupuesto que le ha enviado la competencia y me ha comentado que si le hacemos un 10% de descuento lo firma esta misma tarde."
            },
            allInOne: {
              title: "Todas las acciones en una sola instrucción",
              description: "Crea un contacto, una tarea, una nota y un email con una sola instrucción.",
              example: "Creame un contacto llamado Carmen Rodriguez Pérez de la empresa CRM con el email carmen@crm.com y el teléfono 611223344. Crea la compañía CRM con el dominio CRM.com\n\nCrea una tarea para llamarle el viernes a las 10 de la mañana y comentarle que ya he aumentado mis ventas gracias a la mejor gestión del CRM.\n\nCrea además una nota interna del contacto exponiendo que es la directora de la empresa y es la encargada de recibir sugerencias de los clientes.\n\nPor último, envíale un email con tono emocionado diciendo que ya he probado su herramienta, me ha parecido increíble y me ahorrará mucho tiempo, añádele iconos."
            }
          }
        }
      },
      pt: {
        translation: {
          noCreditsLeft: "Sem créditos disponíveis",
          login: "Iniciar sessão",
          sendingData: "Enviando dados",
          notObjectProcessed: "Não foi possível processar os seguintes objetos:\n",
          audioText: "Pulsa para gravar",
          company: "Empresa",
          contact: "Contato",
          contacts: "Contactos",
          deal: "Negócio",
          task: "Tarefa",
          meeting: "Reunião",
          mail: "E-mail",
          note: "Nota",
          call: "Chamada",
          pipeline: "Pipeline",
          pipelineStages: "Etapas",
          other: "Outro",
          tasks: "Tarefas",
          selectPipeline: "Selecione um pipeline",
          selectPipelineStage: "Selecione uma etapa",
          selectCompany: "Valide os dados da empresa",
          selectContact: "Valide os dados do contato",
          selectTask: "Valide os dados da tarefa",
          selectMeeting: "Valide os dados da reunião",
          selectMail: "Valide os dados do e-mail",
          selectNote: "Valide os dados da nota",
          selectCall: "Valide os dados da chamada",
          selectDeal: "Valide os dados do negócio",
          next: "Próximo",
          finish: "Finalizar",
          cancel: "Cancelar",
          cancelConfirmation: "Tem certeza que deseja cancelar?",
          close: "Fechar",
          ok: "Ok",
          errorProcessingRequest: "Erro ao processar a solicitação",
          errorProcessingRequestDescription: "Tente novamente.",
          errorSendingAudio: "Erro ao enviar o áudio",
          processingAudio: "Processando áudio",
          minutesSaved: "Você economizou",
          minutes: "minutos",
          min: "min",
          savedIn: "em",
          audioSentCorrectly: "Áudio enviado corretamente",
          retry: "Tentar novamente",
          dateTime: "Data e hora",
          duration: "Duração",
          disposition: "Resultado",
          busy: "Ocupado",
          connected: "Conectado",
          noResponse: "Sem resposta",
          wrongNumber: "Número incorreto",
          voiceMessage: "Deixou mensagem de voz",
          liveMessage: "Deixou mensagem ao vivo",
          direction: "Direção",
          inbound: "ENTRADA",
          outbound: "SAÍDA",
          title: "Título",
          description: "Descrição",
          associations: "Associações",
          companies: "Empresas",
          deals: "Negócios",
          companyName: "Nome da empresa",
          companyDomain: "Domínio da empresa",
          selectCompany: "Selecione uma empresa",
          createCompany: "Criar empresa",
          createCompanyDescription: "Crie uma nova empresa",
          userAlreadyExists: "Ocorreu um erro, por favor verifique os dados e tente novamente",
          name: "Nome",
          lastName: "Sobrenome",
          email: "E-mail",
          phoneNumber: "Telefone",
          selectContact: "Selecione um contato",
          createContact: "Criar contato",
          dealName: "Nome do negócio",
          dealValue: "Valor do negócio",
          dealClose: "Data de fechamento do negócio",
          selectDeal: "Selecione um negócio",
          recipients: "Destinatários",
          subject: "Assunto",
          message: "Mensagem",
          meetingTitle: "Título da reunião",
          meetingStartDate: "Data de início",
          meetingEndDate: "Data de término",
          meetingLocation: "Local",
          meetingReminder: "Lembrete",
          meetingDescription: "Descrição",
          meetingInternalNote: "Nota interna",
          low: "Baixa",
          medium: "Média",
          high: "Alta",
          dueDate: "Data de vencimento",
          hsTaskType: "Tipo de tarefa",
          call: "Chamada",
          email: "E-mail",
          todo: "Tarefa",
          crmUpdated: "CRM atualizado corretamente",
          domain: "Domínio",
          phone: "Telefone",
          address: "Endereço",
          audioCompanyText: "Clique para gravar ações sobre esta empresa",
          errorMovingDeal: "Erro ao mover o negócio. Por favor, tente novamente mais tarde.",
          selectStage: "Selecione para qual etapa do pipeline você quer mover este negócio.",
          moveDeal: "Mover para outra etapa",
          save: "Salvar",
          stage: "Etapa",
          amount: "Valor",
          closedate: "Data de fechamento",
          createdate: "Data de criação",
          audioContactText: "Clique para gravar ações sobre este contato",
          record: "Gravar",
          account: "Conta",
          recording: "Gravando áudio...",
          paused: "Pausado",
          microphone: "Microfone",
          recordingCancelled: "Gravação cancelada",
          search: "Buscar",
          sortBy: "Ordenar por",
          newest: "Mais recente",
          oldest: "Mais antigo",
          disconnectGmail: "Desconectar Gmail",
          logout: "Sair",
          connectGmail: "Conectar Gmail",
          gmailConnected: "Gmail conectado corretamente",
          gmailDisconnected: "Gmail desconectado corretamente",
          attendees: "Participantes",
          properties: "Propriedades",
          select_properties: "Selecione propriedades",
          propertyToUpdate: "Propriedade a atualizar",
          selectProperty: "Selecione propriedades",
          updateProperties: "Atualizar propriedades",
          propertiesUpdated: "Propriedades atualizadas corretamente",
          propertiesUpdatedError: "Erro ao atualizar as propriedades",
          createGoogleMeet: "Enviar link do Google Meet",
          createMeeting: "Enviar convite de reunião ao cliente",
          not_started: "Não iniciado",
          none: "Sem prioridade",
          reminders: "Lembretes",
          nearest: "Mais próximo",
          farthest: "Mais distante",
          updateTask: "Atualizar tarefa",
          status: "Status",
          notStarted: "Não iniciado",
          inProgress: "Em andamento",
          completed: "Completado",
          errorUpdatingTask: "Erro ao atualizar a tarefa",
          priority: "Prioridade",
          type: "Tipo",
          identifyingActions: "Identificando ações",
          welcomeToLabia: "Bienvenido a Labia",
          onboardingDescription: "Descubre lo que puedes hacer con Labia a través de estos ejemplos. ¡Haz clic en 'Pruébalo' para ver cómo funciona!",
          example: "Ejemplo",
          tryIt: "Pruébalo",
          showMore: "Mostrar más",
          showLess: "Mostrar menos",
          learnToUseLabia: "Pincha aquí",
          onboardingExamples: {
            contactAndCompany: {
              title: "Criar Contatos e Empresas",
              description: "Crie facilmente novos contatos e empresas com todos os seus detalhes em linguagem natural.",
              example: "Labia, crie um contato chamado Daniel Rodriguez da empresa Labia com o email dani@labia.pro e o telefone 678368027. Crie a empresa Labia com o domínio labia.pro",
            },
            dealsPipeline: {
              title: "Criar Negócios no pipeline de vendas",
              description: "Crie negócios no pipeline de vendas para acompanhar as informações importantes.",
              example: "Crie um negócio no valor de 5000 euros com o nome \"Pacote 100 unidades\" e uma data de fechamento estimada para 20 de junho.",
            },
            tasksAndNotes: {
              title: "Criar Tarefas e Notas",
              description: "Agende tarefas e adicione notas para acompanhar informações importantes e fechar mais vendas.",
              example: "Procure o contato Daniel Rodríguez e crie uma tarefa para ligar para ele na sexta-feira às 10 da manhã para verificar se ele pôde ver nosso catálogo e nossa oferta. Crie também uma nota interna explicando que ele é o diretor de compras, é celíaco e torce para o Real Madrid."
            },
            email: {
              title: "Enviar emails",
              description: "Envie Emails personalizados e com o tom da sua empresa",
              example: "Envie um email para Daniel Rodríguez com tom animado dizendo que já testei o Labia, achei incrível e vai me poupar muito tempo, adicione ícones."
            },
            meeting: {
              title: "Agendar reuniões",
              description: "Agende reuniões com clientes e envie o convite da reunião",
              example: "Agende uma reunião com Daniel Rodríguez para 15 de junho às 12h no escritório dele em Madrid com o Assunto: \"Reunião Demo\", onde falaremos sobre nosso novo produto."
            },
            call: {
              title: "Chamadas",
              description: "Registre chamadas com o resumo do que foi conversado com o cliente",
              example: "Registre uma chamada com o contato Daniel Rodríguez há 10 minutos onde conversamos sobre o orçamento que a concorrência enviou e ele me disse que se dermos 10% de desconto ele assina ainda hoje."
            },
            allInOne: {
              title: "Todas as ações em uma única instrução",
              description: "Crie um contato, uma tarefa, uma nota e um email com uma única instrução.",
              example: "Crie um contato chamado Carmen Rodriguez Pérez da empresa CRM com o email carmen@crm.com e o telefone 611223344. Crie a empresa CRM com o domínio CRM.com\n\nCrie uma tarefa para ligar para ela na sexta-feira às 10 da manhã e comentar que já aumentei minhas vendas graças à melhor gestão do CRM.\n\nCrie também uma nota interna do contato explicando que ela é a diretora da empresa e é responsável por receber sugestões dos clientes.\n\nPor último, envie um email com tom animado dizendo que já testei a ferramenta dela, achei incrível e vai me poupar muito tempo, adicione ícones."
            }
          }
        }
      }
    }
  });

export function getLanguage() {
  return i18n.language.split('-')[0]; // Returns just the ISO-639-1 part (e.g. 'es' from 'es-ES')
}

export default i18n;