import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto/300.css';
import Labia from './record/Labia';
import Validate from './record/Validate';
import * as Sentry from "@sentry/react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Contacts from './modules/contacts';
import Layout from './components/Layout'; // Import the Layout component
import Companies from './modules/Companies';
import Deals from './modules/deals';
import Account from './Account/Account';
import Properties from './modules/Properties';
import Tasks from './modules/Tasks';
import Onboarding from './modules/Onboarding';
const theme = createTheme({
    palette: {
        mode: 'dark',
        info: {
            main: '#243C4D',
            contrastText: "#FFF"
        },
        chipInfo: {
            main: '#9FDEE3',
            contrastText: "#FFF"
        },
        secondary: {
            main: '#76A9AC',
            contrastText: "#FFF"
        }
    },
    components: {
        MuiFab: {
            variants: [
                {
                    props: { variant: 'info' },
                    style: {
                        backgroundColor: '#243C4D !important',
                    },
                }
            ],
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontSize: '0.75rem !important',
                }
            },
            variants: [
                {
                    props: { variant: 'default' },
                    style: {
                        backgroundColor: '#FFFFFF20 !important',
                        borderColor: '#FFFFFF !important',
                        border: '1px solid #FFFFFF !important',
                    }
                }
            ]
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#243C4D !important',
                    top: '10px !important',
                    color: 'white !important',
                    padding: '20px !important',
                    borderRadius: '20px !important',
                    minWidth: '90% !important',
                }
            }
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: {
                        backgroundColor: 'transparent',
                        color: "white",
                        border: "1px solid #76A9AC",
                        borderRadius: "100px",
                        boxShadow: "none !important",
                        fontSize: "1rem !important",
                        fontWeight: "600 !important",
                        '&:hover': {
                            backgroundColor: '#578386',
                        },
                    },
                },
                {
                    props: { variant: 'contained' },
                    style: {
                        borderRadius: "100px",
                        backgroundColor: '#76A9AC',
                        color: "#002657",
                        boxShadow: "none !important",
                        fontSize: "1rem !important",
                        fontWeight: "600 !important",
                        '&:hover': {
                            backgroundColor: '#578386',
                        },
                    }
                },
                {
                    props: { variant: 'danger' },
                    style: {
                        backgroundColor: 'transparent',
                        color: "red",
                        border: "1px solid red",
                        borderRadius: "100px",
                        boxShadow: "none !important",
                        fontSize: "1rem !important",
                        fontWeight: "600 !important",
                        '&:hover': {
                            backgroundColor: '#578386',
                        },
                    }
                },
            ],
        },
        MuiAutocomplete: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    textTransform: 'none',
                    color: "white",
                    lineHeight: "1.4rem",
                    backgroundColor: "#FFFFFF20",
                    borderRadius: "10px",
                    '&::before': {
                        borderBottom: "0 !important",
                    },
                    '&::after': {
                        borderBottom: "0 !important",
                        border: "1px solid white !important",
                        top: 0,
                        borderRadius: "10px"
                    }
                },
            },
        },
        MuiTextField: {
            variants: [
                {
                    props: { variant: 'standard' },
                    style: {
                        textTransform: 'none',
                    },
                }
            ]
        },
        MuiFormLabel: {
            styleOverrides: {
                // Name of the slot
                root: {
                    left: "10px !important",
                    top: "0px !important",
                    marginBottom: "0px !important",
                    '-webkit-transform': "translate(0, 0) scale(1) !important",
                    transform: "translate(0, 0) scale(1) !important",
                }
            }
        },
        MuiInputLabel: {
            variants: [
                {
                    props: { variant: 'standard' },
                    style: {
                        position: "relative",
                        fontSize: "1.4rem",
                    },
                },

            ]
        },
        MuiInput: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    textTransform: 'none',
                    color: "white",
                    lineHeight: "1.4rem",
                    backgroundColor: "#FFFFFF20",
                    borderColor: "transparent !important",
                    borderRadius: "10px",
                    marginTop: "0 !important",
                    padding: 10,
                    '&::before': {
                        borderBottom: "0 !important",
                    },
                    '&::after': {
                        borderBottom: "0 !important",
                        border: "0 !important",
                        top: 0,
                        borderRadius: "10px"
                    }
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: "#002657",
                    '&.Mui-checked': {
                        color: "#002657",
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    textTransform: 'none',
                    color: "white",
                    lineHeight: "1.4rem",
                    backgroundColor: "#FFFFFF20",
                    borderRadius: "10px",
                    borderColor: "transparent !important",
                    marginTop: "0 !important",
                    '&::before': {
                        borderBottom: "0 !important",
                    },
                    '&::after': {
                        borderBottom: "0 !important",
                        border: "0 !important",
                        top: 0,
                        borderRadius: "10px"
                    }
                },
            },
        }
    }
});
if (process.env.NODE_ENV != 'development') {
    Sentry.init({
        dsn: "https://7565465d0af3e338a0b755cf59761688@o4507530299441152.ingest.de.sentry.io/4507530300817488",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                // Additional SDK configuration goes in here, for example:
                maskAllText: false,
                blockAllMedia: false,
            })
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/app\.labia\.pro/],
        // Session Replay
        replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    });
}
const router = createBrowserRouter([
    {
        element: <Layout />,
        path: "/",
        children: [
            {
                path: "/",
                element: <App />
            },
            {
                path: "/account",
                element: <Account />
            },
            {
                path: "/app",
                children: [
                    {
                        index: true,
                        element: <Labia />,
                    },
                    {
                        path: "/app/onboarding",
                        element: <Onboarding />,
                    },
                    {
                        path: "/app/validate",
                        element: <Validate />,
                    },
                    {
                        path: "/app/contacts",
                        children: [
                            {
                                index: true,
                                element: <Contacts.Contacts />,
                            },
                            {
                                path: "/app/contacts/detailed",
                                element: <Contacts.DetailedInfoContact />,
                            }
                        ]
                    },
                    {
                        path: "/app/companies",
                        children: [
                            {
                                index: true,
                                element: <Companies.Companies />,
                            },
                            {
                                path: "/app/companies/detailed",
                                element: <Companies.DetailedInfoCompany />,
                            }
                        ]
                    },
                    {
                        path: "/app/deals",
                        children: [
                            {
                                index: true,
                                element: <Deals.Deals />,
                            },
                            {
                                path: "/app/deals/detailed",
                                element: <Deals.DetailedInfoDeal />,
                            }
                        ]
                    },
                    {
                        path: "/app/tasks",
                        children: [
                            {
                                index: true,
                                element: <Tasks.Tasks />,
                            },
                            {
                                path: "/app/tasks/detailed",
                                element: <Tasks.DetailedInfoTask />,
                            }
                        ]
                    },
                    {
                        path: "/app/properties",
                        element: <Properties />,
                    }
                ]
            }

        ]
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <CssBaseline />
        <ThemeProvider theme={theme} style={{ height: "100%", overflow: "scroll" }}>
            <RouterProvider router={router} style={{ height: "100%", overflow: "scroll" }} />
        </ThemeProvider>
    </>
);

// If you want to start measuring performance in your app, pass a functio.n
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
