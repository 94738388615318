import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const Task = ({task, setTask, loaded}) => {
    const { t } = useTranslation();
    const [newTask, setNewTask] = React.useState({
        subject: task.subject || '',
        priority: task.priority || 'NONE',
        dueDate: task.dueDate,
        hs_task_type: task.hs_task_type || '',
        description: task.description || '',
        status: task.status || 'NOT_STARTED'
    });
    loaded();
    
    return (
        <Stack spacing={2} fullWidth style={{ width: "100%" }}>
            <TextField
                fullWidth
                id="standard-basic"
                label={t('subject')}
                variant="standard"
                multiline
                defaultValue={newTask.subject}
                onChange={(event) => {
                    const newData = {
                        ...newTask,
                        subject: event.target.value,
                        fixed: true
                    };
                    setNewTask(newData);
                    setTask(newData);
                }}
            />

            <FormControl fullWidth>
                <InputLabel id="priority-label">Prioridad</InputLabel>
                <Select
                    labelId="priority-label"
                    id="priority-select"
                    label="Prioridad"
                    defaultValue={newTask.priority}
                    onChange={(event) => {
                        const newData = {
                            ...newTask,
                            priority: event.target.value,
                            fixed: true
                        };
                        setNewTask(newData);
                        setTask(newData);
                    }}
                >
                    <MenuItem value="NONE">{t('none')}</MenuItem>
                    <MenuItem value="LOW">{t('low')}</MenuItem>
                    <MenuItem value="MEDIUM">{t('medium')}</MenuItem>
                    <MenuItem value="HIGH">{t('high')}</MenuItem>
                </Select>
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                    label={t('dueDate')}
                    value={dayjs(newTask.dueDate.split('Z')[0]).tz(dayjs.tz.guess())}
                    onChange={(event) => {
                        const newData = {
                            ...newTask,
                            dueDate: event.format("YYYY-MM-DDTHH:mm:ss.SSS"),
                            fixed: true
                        };
                        setNewTask(newData);
                        setTask(newData);
                    }}
                />
            </LocalizationProvider>
            <FormControl fullWidth>
                <InputLabel id="hs-task-type-label">{t('hsTaskType')}</InputLabel>
                <Select
                    labelId="hs-task-type-label"
                    id="hs-task-type-select"
                    label={t('hsTaskType')}
                    defaultValue={newTask.hs_task_type}
                    onChange={(event) => {
                        const newData = {
                            ...newTask,
                            hs_task_type: event.target.value,
                            fixed: true
                        };
                        setNewTask(newData);
                        setTask(newData);
                    }}
                >
                    <MenuItem value="CALL">{t('call')}</MenuItem>
                    <MenuItem value="EMAIL">{t('email')}</MenuItem>
                    <MenuItem value="TODO">{t('todo')}</MenuItem>
                </Select>
            </FormControl>

            <FormControl fullWidth>
                <InputLabel id="status-label">{t('status')}</InputLabel>
                <Select
                    labelId="status-label"
                    id="status-select"
                    label={t('status')}
                    defaultValue={newTask.status}
                    onChange={(event) => {
                        const newData = {
                            ...newTask,
                            status: event.target.value,
                            fixed: true
                        };
                        setNewTask(newData);
                        setTask(newData);
                    }}
                >
                    <MenuItem value="NOT_STARTED">{t('notStarted')}</MenuItem>
                    <MenuItem value="COMPLETED">{t('completed')}</MenuItem>
                </Select>
            </FormControl>

            <TextField
                fullWidth
                id="description"
                label={t('description')}
                variant="standard"
                multiline
                defaultValue={newTask.description}
                onChange={(event) => {
                    const newData = {
                        ...newTask,
                        description: event.target.value,
                        fixed: true
                    };
                    setNewTask(newData);
                    setTask(newData);
                }}
            />
        </Stack>
    );
}

export default Task;