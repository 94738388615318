import React, { useState, useEffect, useRef } from 'react';
import { Stack, Typography, Grid, Chip, Alert, Button, IconButton, Paper, Card } from '@mui/material';
import api from '../Services/Api';
import { useNavigate, useLocation } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import Loading from '../components/Loading';
import MailOutlined from '@mui/icons-material/MailOutlined';
import PhoneOutlined from '@mui/icons-material/PhoneOutlined';
import WorkOutlineOutlined from '@mui/icons-material/WorkOutlineOutlined';
import SearchComponent from '../components/SearchComponent';
import ArrowBack from '@mui/icons-material/ArrowBack';
import DetailedInfo from './DetailedInfo';
import PlaceOutlined from '@mui/icons-material/PlaceOutlined';
import WebIcon from '@mui/icons-material/Web';
import { useTranslation } from 'react-i18next';
const Contacts = () => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const [loading, setLoading] = useState(true);
    const crmUpdated = state != undefined ? state.ok : false
    const [filteredContacts, setFilteredContacts] = useState([]);
    var previousController = useRef();
    const [createdate, setCreatedate] = useState("DESCENDING");
   
    const navigate = useNavigate();
    useEffect(() => {
        api.fetchContacts({ firstName: undefined, after: 0, createdate: createdate, noCache: true }).then((response) => {
            setFilteredContacts(response.options || []);
            setLoading(false);
        }).catch((error) => {
            console.error("Error fetching contacts", error);
            setLoading(false);
        });
    }, []);

    const handleSearchChange = (text) => {
        if (previousController.current) {
            previousController.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;

        api.fetchContacts({ firstName: text.toLowerCase(), after: 0, createdate: createdate, signal: signal }).then((response) => {
            setFilteredContacts(response.options || []);
        }).catch((error) => {
            console.error("Error fetching contacts", error);
        });
    };

    

    const handleSortChange = (searchTerm, createdate) => {
        setLoading(true);
        setCreatedate(createdate);
        api.fetchContacts({ firstName: searchTerm, after: 0, createdate: createdate }).then((response) => {
            setFilteredContacts(response.options || []);
            setLoading(false);
        }).catch((error) => {
            console.error("Error fetching contacts", error);
            setLoading(false);
        });
    }

    return (
        <Stack style={{padding: '1rem', maxWidth: '100%', margin: 'auto' }}>
            <Stack direction="row" spacing={2} style={{marginBottom: '1rem'}} alignItems="center">
                <IconButton style={{ color: 'white'}} onClick={() => {
                    navigate("/app");
                }}>
                    <ArrowBack />
                </IconButton>
                <Typography variant="h5" style={{color: 'white', textAlign: 'left', width: '100%', verticalAlign: 'middle'}} >{t('contacts')}</Typography>
            </Stack>
            <Loading loading={loading} />
            {crmUpdated &&
                <Alert style={{
                    backgroundColor: "#366E6B", color: "white", position: "absolute", width: "90%", left: "5%", right: "5%",
                    top: "5%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", textAlign: "center"
                }}
                    icon={<CheckIcon style={{ color: "white" }} fontSize="inherit" />}>
                    {t('crmUpdated')}
                </Alert>
            }
           <SearchComponent createdate={createdate} handleSortChange={handleSortChange} handleSearchChange={handleSearchChange} />

            <Grid container spacing={2} style={{ marginTop: '10px' }}>
                {filteredContacts.map((contact) => (
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <ContactCard contact={contact} />
                    </Grid>
                ))}
            </Grid>

        </Stack>
    );
};

const ContactCard = ({ contact }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return <Card onClick={() => navigate("/app/contacts/detailed", { state: { contact: contact } })}  style={{ borderRadius: '1.5rem', padding: '1rem', margin: '0.25rem', backgroundColor: '#00000020' }} elevation={3} square={false}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', gap: '10px', width: '100%'}}>
            <Typography style={{ fontSize: '16px', whiteSpace: 'normal !important' }}>{getContactName(contact)}</Typography>
            <Chip icon={<MailOutlined />} style={{fontSize: '0.75rem'}} label={contact.email ?? t('email')} variant="outlined" color="chipInfo" />
            <Chip icon={<PhoneOutlined />} style={{fontSize: '0.75rem'}} label={contact.phone ?? t('phone')} variant="outlined" color="default" />
            <Chip icon={<WorkOutlineOutlined />} style={{fontSize: '0.75rem'}} label={contact.company != undefined && contact.company.name != undefined ? contact.company.name : t('company')} variant="outlined" color="default" />
        </div>
    </Card>
}

const DetailedInfoContact = () => {
    const { state } = useLocation();
    const { t } = useTranslation();
    const contact = state.contact;
    var contactProperties = getPropertiesContact(contact, t);

    let company = contact.company;
    var jsonObject = { contact: contact };
    if (company != undefined) {
        jsonObject.company = company;
    }
    return <>
        <DetailedInfo title={getContactName(contact)} properties={contactProperties} routeBack="/app/contacts" audioText={t('audioContactText')} jsonObject={jsonObject} />
    </>
}

export const getContactName = (contact) => {
    var name = `${contact.firstname ?? ""} ${contact.lastname ?? ""}`
    if (name.trim().length == 0 || name.includes("null")) {
        name = "--"
    }
    return name
}

export const getPropertiesContact = (contact, t) => {
    var properties = [
        {icon: <MailOutlined />, label: t('email'), value: contact.email},
        {icon: <PhoneOutlined />, label: t('phone'), value: contact.phone}
    ]

    if (contact.company != undefined) {
        properties.push({icon: <WorkOutlineOutlined />, label: t('company'), value: contact.company.name});
        properties.push({icon: <WebIcon />, label: t('domain'), value: contact.company.domain});
        properties.push({icon: <PlaceOutlined />, label: t('address'), value: getAddressCompany(contact.company, t)});
    }

    return properties;
}

const getAddressCompany = (company, t) => {
    var address = [];
    if (company.addres) address.push(company.addres);
    if (company.city) address.push(company.city);
    if (company.country) address.push(company.country);
    if (address.length === 0) return t('address');
    return address.join(", ");
}

export default { Contacts, ContactCard, DetailedInfoContact };