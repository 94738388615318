import React, { useState, useEffect, useRef } from 'react';
import { Stack, Typography, Grid, Chip, Alert, IconButton, Paper, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tabs, Tab, TabPanel, CircularProgress } from '@mui/material';
import api from '../Services/Api';
import { useNavigate, useLocation } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import Loading from '../components/Loading';
import SearchComponent from '../components/SearchComponent';
import ArrowBack from '@mui/icons-material/ArrowBack';
import DetailedInfo from './DetailedInfo';
import { useTranslation } from 'react-i18next';
import { getContactName, getPropertiesContact } from './contacts';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkOutlineOutlined from '@mui/icons-material/WorkOutline';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import dayjs from 'dayjs';
import Task from '../record/components/Task';

const Tasks = () => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const [loading, setLoading] = useState(true);
    const crmUpdated = state != undefined ? state.ok : false
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [createdate, setCreatedate] = useState("DESCENDING");
    const previousController = useRef();
    const [status, setStatus] = useState("NOT_STARTED");
    const navigate = useNavigate();

    useEffect(() => {
        api.fetchTasks("", createdate, status).then((response) => {
            setFilteredTasks(response);
            setLoading(false);
        }).catch((error) => {
            console.error("Error fetching tasks", error);
            setLoading(false);
        });
    }, []);

    const handleSearchChange = (searchTerm) => {
        if (previousController.current) {
            previousController.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;
        setLoading(true);
        api.fetchTasks(searchTerm.toLowerCase(), createdate, status, signal).then((response) => {
            setFilteredTasks(response);
            setLoading(false);
        }).catch((error) => {
            console.error("Error fetching tasks", error);
            setLoading(false);
        });
    };

    const handleSortChange = (searchTerm, createdate) => {
        setLoading(true);
        setCreatedate(createdate);
        api.fetchTasks(searchTerm.toLowerCase(), createdate, status).then((response) => {
            setFilteredTasks(response);
            setLoading(false);
        }).catch((error) => {
            console.error("Error fetching tasks", error);
            setLoading(false);
        });
    }

    const handleChangeStatus = (event, newValue) => {
        setLoading(true);
        setStatus(newValue);
        api.fetchTasks("", createdate, newValue).then((response) => {
            setFilteredTasks(response);
            setLoading(false);
        }).catch((error) => {
            console.error("Error fetching tasks", error);
            setLoading(false);
        });
    }

    return (
        <Stack style={{ padding: '1rem', width: '100%', margin: 'auto' }}>
            <Stack direction="row" spacing={2} style={{ marginBottom: '1rem' }} alignItems="center">
                <IconButton style={{ color: 'white' }} onClick={() => {
                    navigate("/app");
                }}>
                    <ArrowBack />
                </IconButton>
                <Typography variant="h5" style={{ color: 'white', textAlign: 'left', width: '100%', verticalAlign: 'middle' }} >{t('tasks')}</Typography>
            </Stack>
            <Loading loading={loading} />
            {crmUpdated &&
                <Alert style={{
                    backgroundColor: "#366E6B", color: "white", position: "absolute", width: "90%", left: "5%", right: "5%",
                    top: "5%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", textAlign: "center"
                }}
                    icon={<CheckIcon style={{ color: "white" }} fontSize="inherit" />}>
                    {t('crmUpdated')}
                </Alert>
            }
            <SearchComponent createdate={createdate} handleSortChange={handleSortChange} handleSearchChange={handleSearchChange} titleNewest={'nearest'} titleOldest={'farthest'} />

            <Tabs
                value={status}
                onChange={handleChangeStatus}
                scrollButtons="auto"
                centered
                aria-label="scrollable force tabs example"
                style={{ marginBottom: '2rem', width: '100%' }}
            >
                <Tab value={'NOT_STARTED'} label={t('notStarted')} />
                <Tab value={'COMPLETED'} label={t('completed')} />
            </Tabs>
            <Grid container spacing={1} style={{ marginTop: '10px' }}>
                {filteredTasks.map((task) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TaskCard task={task} />
                    </Grid>
                ))}
            </Grid>
            

        </Stack>
    );
};

const TaskCard = ({ task }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return <Paper style={{ borderRadius: '1.5rem', padding: '1rem', margin: '0.25rem', backgroundColor: '#00000020' }} elevation={3} square={false}>
        <div onClick={() => { navigate("/app/tasks/detailed", { state: { task: task } }) }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', gap: '10px', width: '100%' }}>
            <Typography style={{ fontSize: '16px', whiteSpace: 'normal !important' }}>{task.title ?? "--"}</Typography>
            {task.description && <Typography style={{ fontSize: '14px', whiteSpace: 'normal !important', color: '#999' }}>{task.description ?? "--"}</Typography>}
            <Stack direction="row" spacing={1}>
            <Chip label={dayjs(task.dueDate.split('Z')[0]).tz(dayjs.tz.guess()).format('DD/MM/YYYY HH:mm') ?? t('timestamp')} variant="outlined" color="default" />
                <Chip label={t(task.priority.toLowerCase()) ?? t('priority')} variant="outlined" color={task.priority === 'HIGH' ? 'error' : task.priority === 'MEDIUM' ? 'warning' : 'secondary'} />
            </Stack>
            <Stack direction="row" spacing={1}>
                <Chip label={t(task.status.toLowerCase()) ?? t('status')} variant="outlined" color={task.status === 'COMPLETED' ? 'success' : 'error'} />
                <Chip label={t(task.type.toLowerCase()) ?? t('type')} variant="outlined" color="default" />
            </Stack>
        </div>
    </Paper>
}

const DetailedInfoTask = () => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const [loading, setLoading] = useState(true);
    const [task, setTask] = useState(state.task);
    const [jsonObject, setJsonObject] = useState({});
    const [open, setOpen] = useState(false);
    const [taskToUpdate, setTaskToUpdate] = useState({});
    const [error, setError] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
    
    const [taskProperties, setTaskProperties] = useState([]);
    useEffect(() => {
        loadData()
    }, []);

    const loadData = () => {
        api.fetchTask(task.id).then((response) => {
            setTaskToUpdate(
                {
                    subject: response.title,
                    hs_task_type: response.type,
                    description: response.description,
                    priority: response.priority,
                    status: response.status,
                    dueDate: response.dueDate
                }
            );
            setTask(response);
            setLoading(false);
            setJsonObject({task: response});
            var properties = [];
            if (response.contacts?.length > 0) {
                let contact = response.contacts[0];
                var contactProperties = [{
                    icon: <PersonIcon />,
                    label: t('contact'),
                    value: getContactName(contact)
                }];
                contactProperties = [...contactProperties, ...getPropertiesContact(contact, t)];
                properties.push({label: t('contact')});
                properties.push(...contactProperties);
            }
            console.log(task);
            setTaskProperties([
                {icon: <CheckCircleIcon />, label: t('status'), value: t(response.status.toLowerCase())},
                {icon: <DescriptionIcon />, label: t('description'), value: response.description},
                {icon: <WorkOutlineOutlined />, label: t('type'), value: t(response.type.toLowerCase())},
                {icon: <PriorityHighIcon />, label: t('priority'), value: t(response.priority.toLowerCase())},
                {icon: <NotificationsIcon />, label: t('reminders'), value: response.reminders},
                {icon: <CalendarMonthIcon />, label: t('createdate'), value: `${dayjs(response.dueDate.split('Z')[0]).tz(dayjs.tz.guess()).format('DD/MM/YYYY HH:mm')}`},
                ...properties
            ])
        }).catch((error) => {
            console.error("Error fetching task", error);
            setLoading(false);
        });
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleSave = () => {
        var taskNewData = {...taskToUpdate};
        taskNewData.id = task.id;
        setLoadingDialog(true);
        api.updateTask(task.id, taskNewData).then((response) => {
            setOpen(false);
            setLoading(false);
            setLoadingDialog(false);
            loadData();
        }).catch((error) => {
            console.error("Error updating task", error);
            setLoading(false);
            setLoadingDialog(false);
        });
    }

    return <>
        <Loading loading={loading} />
        <DetailedInfo 
            title={task.title} 
            properties={taskProperties} 
            routeBack="/app/tasks" 
            audioText={''} 
            jsonObject={jsonObject} 
            showRecorder={false} 
            customView={<>
                <Button style={{ width: "100%" }} variant="contained" color="primary" onClick={() => {setOpen(true)}}>
                    <DriveFileMoveOutlinedIcon style={{ marginRight: "5px" }} />
                    {t('updateTask')}
                    </Button>

        </>}/>
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>{t('updateTask')}</DialogTitle>
            <DialogContent>
                <Stack direction="column" alignItems="center" spacing={1} >
                    {error && <Alert severity="error">{t('errorUpdatingTask')}</Alert>}
                    {loadingDialog && <CircularProgress />}
                    {!loadingDialog && <Task task={taskToUpdate} setTask={setTaskToUpdate} loaded={() => {}} />}
                </Stack>
            </DialogContent>
            <DialogActions>
                        <Button onClick={handleClose} style={{ color: "white", fontWeight: '700' }} >
                            {t('cancel')}
                        </Button>
                        <Button onClick={handleSave} style={{ color: "white", fontWeight: '700' }}>
                            {t('save')}
                        </Button>
                    </DialogActions>
        </Dialog>
    </>
}

export default {
    Tasks,
    TaskCard,
    DetailedInfoTask
}; 