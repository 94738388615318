import React from 'react';
import { Stack, TextField, RadioGroup, FormControlLabel, Radio, Typography, Select, MenuItem, FormGroup, Checkbox } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker, DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

const Properties = ({ properties, setProperties }) => {
    const handlePropertyChange = (index, value) => {
        var updatedProperties = [...properties];
        if (Array.isArray(updatedProperties[index].value)) {
            if (updatedProperties[index].value.includes(value)) {
                // Remove value if already exists
                updatedProperties[index].value = updatedProperties[index].value.filter(v => v !== value);
            } else {
                // Add new value to array
                updatedProperties[index].value = [...updatedProperties[index].value, value];
            }
        } else {
            updatedProperties[index].value = value;
        }
        setProperties(updatedProperties);
    };

    const renderPropertyInput = (property, index) => {
        if (property.type === 'bool' || property.fieldType === 'radio') {
            return (
                <Stack spacing={1}>
                    <Typography>{property.label}</Typography>
                    <RadioGroup
                        value={property.value?.toString()}
                        onChange={(event) => handlePropertyChange(index, event.target.value)}
                    >
                        {property.options.map((option, optionIndex) => (
                            <FormControlLabel key={optionIndex} value={option.value} control={<Radio style={{ color: "white" }} />} label={option.label} />
                        ))}
                    </RadioGroup>
                </Stack>
            );
        }

        if (property.type === 'enumeration' && property.options) {
            let value = property.value ? property.value : '';
            if (property.fieldType === 'checkbox') {
                property.value = Array.isArray(value) ? value.map(v => v.trim()) : value.split(';').map(v => v.trim());
            }
            if (property.fieldType === 'checkbox') {
                return (
                    <Stack spacing={1}>
                        <Typography>{property.label}</Typography>
                    
                    <FormGroup
                        multiple={property.fieldType === 'checkbox'}
                    >
                        {property.options.map((option, optionIndex) => (
                            <FormControlLabel
                                control={
                                    <Checkbox checked={property.value.includes(option.value)} onChange={(event) => handlePropertyChange(index, event.target.name)} name={option.value} />
                                }
                                label={option.label}
                            />
                        ))}

                        </FormGroup>
                    </Stack>
                );
            } else {
                return (
                    <Stack spacing={1}>
                        <Typography>{property.label}</Typography>
                        <Select
                            value={property.value}
                            multiple={property.fieldType === 'checkbox'}
                            onChange={(event) => handlePropertyChange(index, event.target.value)}
                        >
                            {property.options.map((option, optionIndex) => (
                                <MenuItem key={optionIndex} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </Select>
                    </Stack>
                );
            }

        }

        if (property.type === 'date' || property.type === 'dateTime') {
            return (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {property.type === 'date' ? (
                        <DatePicker
                            label={property.label}
                            value={dayjs(property.value)}
                            onChange={(event) => {
                                handlePropertyChange(index, event.format("YYYY-MM-DD"));
                            }}
                        />
                    ) : (
                        <DateTimePicker
                            label={property.label}
                            value={dayjs(property.value)}
                            onChange={(event) => {
                                handlePropertyChange(index, event.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
                            }}
                        />
                    )}
                </LocalizationProvider>
            );
        }

        if (property.type === 'number') {
            return (
                <TextField
                    fullWidth
                    type="number"
                    label={property.label}
                    value={property.value || ''}
                    onChange={(event) => handlePropertyChange(index, event.target.value)}
                    helperText={property.description}
                />
            );
        }

        // Default to string/text input
        return (
            <TextField
                fullWidth
                type="text"
                label={property.label}
                value={property.value || ''}
                onChange={(event) => handlePropertyChange(index, event.target.value)}
                helperText={property.description}
                multiline={property.type === 'string' && property.multiline}
            />
        );
    };

    return (
        <Stack spacing={2} style={{ width: "100%" }}>
            {properties.map((property, index) => (
                <div key={index}>
                    {renderPropertyInput(property, index)}
                </div>
            ))}
        </Stack>
    );
};

export default Properties;
