import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardActions, Button, Typography, Stack, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ContactsIcon from '@mui/icons-material/Contacts';
import TaskIcon from '@mui/icons-material/Task';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useOutletContext } from 'react-router-dom';
import PhoneIcon from '@mui/icons-material/Phone';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import HelpIcon from '@mui/icons-material/Help';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

const OnboardingCard = ({ title, description, example, icon }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [shouldShowMore, setShouldShowMore] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      const lineHeight = parseInt(window.getComputedStyle(textRef.current).lineHeight);
      const height = textRef.current.clientHeight;
      setShouldShowMore(height > lineHeight * 5);
    }
  }, [example]);

  return (
    <Card sx={{ 
      height: '100%', 
      minHeight: '50vh',
      display: 'flex', 
      flexDirection: 'column', 
      backgroundColor: '#00000020', 
      borderRadius: '1.5rem',
      color: 'white',
      minWidth: '90vw',
      margin: '0 1rem',
      scrollSnapAlign: 'center'
    }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
          {icon}
          <Typography variant="h6" component="div">
            {title}
          </Typography>
        </Stack>
        <Typography variant="body2" sx={{ mb: 2 }}>
          {description}
        </Typography>
        <Typography variant="body2" sx={{ fontStyle: 'italic', opacity: 0.8 }}>
          {t('example')}:
        </Typography>
        <div
          ref={textRef}
          style={{
            overflow: 'hidden',
            maxHeight: expanded ? 'none' : "16vh",
            transition: 'max-height 0.3s ease-out',
            marginBottom: '0.5rem'
          }}
        >
            { 
                example.split('\n').map((line, index) => (
                    <Typography key={index} variant="body2" sx={{ opacity: 0.8, marginBottom: '0.5rem' }}>
                        {line}
                    </Typography>
                ))
            }
        </div>
        {shouldShowMore && (
          <Button
            size="small"
            onClick={() => setExpanded(!expanded)}
            startIcon={expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            sx={{ color: 'white', textTransform: 'none' }}
          >
            {expanded ? t('showLess') : t('showMore')}
          </Button>
        )}
      </CardContent>
      <CardActions sx={{ marginTop: 'auto', padding: 2 }}>
        <Button 
          variant="contained" 
          fullWidth
          onClick={() => navigate("/app", { state: { example: example } })}
        >
          {t('tryIt')}
        </Button>
      </CardActions>
    </Card>
  );
};

const Onboarding = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const [currentCard, setCurrentCard] = useState(0);
  const { setHide, user } = useOutletContext();

  useEffect(() => {
    setHide(true)
    return () => {
      setHide(false)
    }
  }, [])
  
  const getIconForKey = (key) => {
    switch (key) {
      case 'contactAndCompany':
        return <ContactsIcon sx={{ fontSize: 30 }} />;
      case 'dealsPipeline':
        return <AccountBalanceIcon sx={{ fontSize: 30 }} />;
      case 'tasksAndNotes':
        return <TaskIcon sx={{ fontSize: 30 }} />;
      case 'email':
        return <EmailIcon sx={{ fontSize: 30 }} />;
      case 'meeting':
        return <MeetingRoomIcon sx={{ fontSize: 30 }} />;
      case 'call':
        return <PhoneIcon sx={{ fontSize: 30 }} />;
      case 'allInOne':
        return <AllInclusiveIcon sx={{ fontSize: 30 }} />;
      default:
        return <HelpIcon sx={{ fontSize: 30 }} />;
    }
  };

  const examplesKeys = [
    "contactAndCompany",
    "dealsPipeline",
    "tasksAndNotes",
    "email",
    "meeting",
    "call",
    "allInOne"
  ]

  const examples = examplesKeys.map((key) => ({
    title: t(`onboardingExamples.${key}.title`),
    description: t(`onboardingExamples.${key}.description`),
    example: t(`onboardingExamples.${key}.example`),
    icon: getIconForKey(key)
  }));

  const scrollToCard = (index) => {
    if (scrollRef.current) {
      const cardWidth = scrollRef.current.offsetWidth;
      scrollRef.current.scrollTo({
        left: index * cardWidth,
        behavior: 'smooth'
      });
      setCurrentCard(index);
    }
  };

  const handleScroll = () => {
    if (scrollRef.current) {
      const cardWidth = scrollRef.current.offsetWidth;
      const scrollPosition = scrollRef.current.scrollLeft;
      const newIndex = Math.round(scrollPosition / cardWidth);
      setCurrentCard(newIndex);
    }
  };

  return (
    <Stack spacing={3} sx={{ padding: '1rem', width: '100%', margin: 'auto' }}>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h5" style={{ color: 'white' }}>
            {t('welcomeToLabia')}
          </Typography>
        </Stack>
        <IconButton 
          style={{ color: 'white' }} 
          onClick={() => navigate("/app")}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      
      <Typography variant="body1" style={{ color: 'white' }} gutterBottom>
        {t('onboardingDescription')}
      </Typography>
      
      <div 
        ref={scrollRef}
        onScroll={handleScroll}
        style={{
          width: '100%',
          overflowX: 'auto',
          scrollSnapType: 'x mandatory',
          display: 'flex',
          WebkitOverflowScrolling: 'touch',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          padding: '1rem 0'
        }}
      >
        {examples.map((example, index) => (
          <OnboardingCard key={index} {...example} />
        ))}
      </div>

      <Stack 
        direction="row" 
        spacing={2} 
        alignItems="center"
        justifyContent="center"
      >
        <IconButton 
          style={{ color: 'white' }} 
          onClick={() => scrollToCard(currentCard - 1)}
          disabled={currentCard === 0}
        >
          <ChevronLeftIcon />
        </IconButton>

        {examples.map((_, index) => (
          <div
            key={index}
            onClick={() => scrollToCard(index)}
            style={{
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              backgroundColor: currentCard === index ? 'white' : 'rgba(255, 255, 255, 0.5)',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease'
            }}
          />
        ))}

        <IconButton 
          style={{ color: 'white' }} 
          onClick={() => scrollToCard(currentCard + 1)}
          disabled={currentCard === examples.length - 1}
        >
          <ChevronRightIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default Onboarding; 