import React, { useState } from 'react';
import { TextField, Button, Stack, MenuItem, Typography } from '@mui/material';
import ContactAutocomplete from './ContactAutocomplete';
import CompanyAutocomplete from './CompanyAutocomplete';
import DealAutocomplete from './DealAutocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { DateTimePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useTranslation } from 'react-i18next';
dayjs.extend(utc);
dayjs.extend(timezone);
const Call = ({ call, createCall, loaded }) => {
  const { t } = useTranslation();
  const [timestamp, setTimestamp] = useState(call.date ? call.date.split('Z')[0] : new Date());
  const [formData, setFormData] = useState({
    date: timestamp.valueOf(),
    title: call.title || "",
    description: call.description || "",
    duration: call.duration || 0,
    disposition: call.disposition || "f240bbac-87c9-4f6e-bf70-924b57d47db7",
    direction: call.direction || "OUTBOUND",
    contact: call.contact || {},
    companies: call.companies || [],
    deals: call.deals || []
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      fixed: true
    });
    createCall({
      ...formData,
      [name]: value,
      fixed: true
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  React.useEffect(() => {
    loaded();
  }, [call]);

  return (
    <Stack spacing={2} fullWidth style={{ width: "100%" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
              label={t('dateTime')}
              defaultValue={dayjs(timestamp).tz(dayjs.tz.guess())}
              onAccept={(value) => {
                setTimestamp(value)
                setFormData({
                  ...formData,
                  date: value.format("YYYY-MM-DDTHH:mm:ss.SSS")
              });
              createCall({
                ...formData,
                date: value.format("YYYY-MM-DDTHH:mm:ss.SSS")
              });
            }}
          />
        </LocalizationProvider>
        
        <TextField
          label={t('duration')}
          name="duration"
          type="number"
          value={formData.duration}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          label={t('disposition')}
          name="disposition"
          value={formData.disposition}
          onChange={handleChange}
          select
          fullWidth
        >
          <MenuItem value="9d9162e7-6cf3-4944-bf63-4dff82258764">{t('busy')}</MenuItem>
          <MenuItem value="f240bbac-87c9-4f6e-bf70-924b57d47db7">{t('connected')}</MenuItem>
          <MenuItem value="a4c4c377-d246-4b32-a13b-75a56a4cd0ff">{t('liveMessage')}</MenuItem>
          <MenuItem value="b2cf5968-551e-4856-9783-52b3da59a7d0">{t('voiceMessage')}</MenuItem>
          <MenuItem value="73a0d17f-1163-4015-bdd5-ec830791da20">{t('noResponse')}</MenuItem>
          <MenuItem value="17b47fee-58de-441e-a44c-c6300d46f273">{t('wrongNumber')}</MenuItem>
        </TextField>
        <TextField
          label={t('direction')}
          name="direction"
          value={formData.direction}
          onChange={handleChange}
          select
          fullWidth
        >
          <MenuItem value="INBOUND">{t('inbound')}</MenuItem>
          <MenuItem value="OUTBOUND">{t('outbound')}</MenuItem>
        </TextField>
        <ContactAutocomplete
          opts={formData.contact.options || [formData.contact]}
          contact={formData.contact}
          setContact={(value) => {
            setFormData({ ...formData, contact: value });
            createCall({ ...formData, contact: value });
          }}
        />
        <TextField
          label={t('title')}
          name="title"
          value={formData.title}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          label={t('description')}
          name="description"
          multiline
          value={formData.description}
          onChange={handleChange}
          fullWidth
        />

        <Typography variant="h6">{t('associations')}</Typography>

        <Stack spacing={2} fullWidth style={{ width: "100%", alignItems: "center" }} direction="row">
          <Typography variant="h6">{t('companies')}</Typography>
          <AddCircleIcon onClick={() => {
            const newCompanies = [...formData.companies, {}];
            setFormData({ ...formData, companies: newCompanies });
            createCall({ ...formData, companies: newCompanies });
          }} />
        </Stack>
        {formData.companies.map((company, index) => (
          <Stack key={index} spacing={2} fullWidth style={{ width: "100%", alignItems: "center" }} direction="row">
            <CompanyAutocomplete
              opts={formData.companies}
              company={company}
              setCompany={(value) => {
                const newCompanies = [...formData.companies];
                newCompanies[index] = value;
                setFormData({ ...formData, companies: newCompanies });
                createCall({ ...formData, companies: newCompanies });
              }}
            />
            <RemoveCircleIcon onClick={() => {
              const newCompanies = formData.companies.filter((_, i) => i !== index);
              setFormData({ ...formData, companies: newCompanies });
              createCall({ ...formData, companies: newCompanies });
            }} />
          </Stack>
        ))}

        <Stack spacing={2} fullWidth style={{ width: "100%", alignItems: "center" }} direction="row">
          <Typography variant="h6">{t('deals')}</Typography>
          <AddCircleIcon onClick={() => {
            const newDeals = [...formData.deals, {}];
            setFormData({ ...formData, deals: newDeals });
            createCall({ ...formData, deals: newDeals });
          }} />
        </Stack>
        {formData.deals.map((deal, index) => (
          <Stack key={index} spacing={2} fullWidth style={{ width: "100%", alignItems: "center" }} direction="row">
            <DealAutocomplete
              opts={formData.deals}
              deal={deal}
              setDeal={(value) => {
                const newDeals = [...formData.deals];
                newDeals[index] = value;
                setFormData({ ...formData, deals: newDeals });
                createCall({ ...formData, deals: newDeals });
              }}
            />
            <RemoveCircleIcon onClick={() => {
              const newDeals = formData.deals.filter((_, i) => i !== index);
              setFormData({ ...formData, deals: newDeals });
              createCall({ ...formData, deals: newDeals });
            }} />
          </Stack>
        ))}
      </Stack>
  );
};

export default Call;
