import React, { useState, useEffect, useRef } from 'react';
import { Popper, RadioGroup, FormControlLabel, Radio, Stack, Grid, Typography, Chip, Divider, Tabs, Tab, Dialog, DialogContent, DialogTitle, DialogContentText, Button, DialogActions, CircularProgress, Backdrop, Alert, IconButton, Paper } from '@mui/material';
import dayjs from 'dayjs';
import api from '../Services/Api';
import { useNavigate, useLocation } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import FilterAltOutlined from '@mui/icons-material/FilterAltOutlined';
import Api from '../Services/Api';
import Loading from '../components/Loading';
import ArrowBack from '@mui/icons-material/ArrowBack';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import SearchComponent from '../components/SearchComponent';
import DetailedInfo from './DetailedInfo.js';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import { getPropertiesContact, getContactName } from './contacts.js';
import { useTranslation } from 'react-i18next';
const Deals = () => {
    const { state } = useLocation();
    const { t } = useTranslation();
    const crmUpdated = state != undefined ? state.ok : false
    const [filteredDeals, setFilteredDeals] = useState([]);
    const [deals, setDeals] = useState([]);
    const [pipeline, setPipeline] = React.useState(undefined);
    const [pipelines, setPipelines] = React.useState([]);
    const [stage, setStage] = React.useState(-1);
    const [stages, setStages] = React.useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [dealSelected, setDealSelected] = useState(null);
    const [closedate, setClosedate] = useState("DESCENDING");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const previousController = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchDeals(undefined, undefined, true);
    }, []);

    const handleSearchChange = (text) => {
        setSearchTerm(text);
        setPipeline(undefined);
        setStage(-1);
        fetchDeals(text, false);
    };

    const handleChangePipeline = (event) => {
        var newPipeline = event.target.value;
        let newStage = deals.find((option) => { return option.pipeline.id == newPipeline }).stages[0].id;
        filterDeals(newPipeline, newStage, deals);
        handleSortChange();
    };

    const handleChangeStage = (event, newStage) => {
        setStage(newStage);
        filterDeals(pipeline, newStage, deals);
    };

    const handleSortChange = (searchTerm, createdate) => {
        setClosedate(createdate);
        fetchDeals(searchTerm);
    }

    const fetchDeals = (search, filterPipeline = true, noCache = false) => {
        setLoading(true);
        if (previousController.current) {
            previousController.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;
        var options = {
            after: 0,
            closedate: closedate,
            signal: signal,
            noCache: noCache
        }
        if (search && search.length > 0 || searchTerm && searchTerm.length > 0) {
            if (search != undefined) {
                options.value = search;
            } else {
                options.value = searchTerm;
            }
        }

        api.fetchDealsGrouped(options).then((response) => {
            if (response.options.length > 0) {
                let pipelineId = response.options[0].pipeline.id
                let pipe = filterPipeline ? pipeline || pipelineId : pipelineId;
                let options = response.options.find((option) => { return option.pipeline.id == pipe });
                let stages = options.stages;
                let stage = (search && search != "" || search == undefined) && options.deals.length > 0 ? options.deals[0].stage : stages[0];
                setDeals(response.options);
                setStages(stages);
                setStage(stage.id);
                setPipelines(response.options.map((option) => { return option.pipeline }));
                filterDeals(pipe, stage.id, response.options);
            } else {
                setFilteredDeals([]);
            }
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            console.error("Error fetching deals", error);
        });
    }


    const filterDeals = (pipeline, stage, deals) => {
        setStage(stage);
        setPipeline(pipeline);
        var allDealsPipeline = deals.find((option) => {
            if (option && option.pipeline) { return option.pipeline.id == pipeline } else return false
        }).deals
        allDealsPipeline = allDealsPipeline.filter((deal) => {
            return deal.stage.id == stage
        });
        setFilteredDeals(allDealsPipeline);
    }

    return (
        <Stack style={{ padding: '1rem', height: 'calc(100% - 2rem)', width: '100%', margin: 'auto' }}>
            <Loading loading={loading} />
            <Stack direction="row" spacing={2} style={{ marginBottom: '1rem' }} alignItems="center">
                <IconButton style={{ color: 'white' }} onClick={() => {
                    navigate("/app");
                }}>
                    <ArrowBack />
                </IconButton>
                <Typography variant="h5" style={{ color: 'white', textAlign: 'left', width: '100%', verticalAlign: 'middle' }} >{t('deals')}</Typography>
            </Stack>
            {crmUpdated &&
                <Alert style={{
                    backgroundColor: "#366E6B", color: "white", position: "absolute", width: "90%", left: "5%", right: "5%",
                    top: "5%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", textAlign: "center"
                }}
                    icon={<CheckIcon style={{ color: "white" }} fontSize="inherit" />}>
                    {t('crmUpdated')}
                </Alert>
            }
            <Stack direction="row" spacing={1} alignItems="center" style={{width: '100%'}}>
                <SearchComponent
                    createdate={closedate}
                    handleSortChange={handleSortChange}
                    handleSearchChange={handleSearchChange}
                />
                <div style={{display: pipelines.length > 1 ? 'block' : 'none'}}>
                    <IconButton
                        style={{ backgroundColor: drawerOpen ? '#FFFFFF50' : 'transparent', color: 'white' }}
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                            setDrawerOpen(!drawerOpen)
                        }
                        }>
                        <FilterAltOutlined />
                    </IconButton>
                    <Popper
                        placement="bottom-end"
                        open={drawerOpen}
                        disablePortal={false}
                        anchorEl={anchorEl}
                        modifiers={[
                            {
                                name: 'flip',
                                enabled: true,
                                options: {
                                    altBoundary: true,
                                    rootBoundary: 'document',
                                    padding: 8,
                                },
                            },
                            {
                                name: 'preventOverflow',
                                enabled: true,
                                options: {
                                    altAxis: true,
                                    altBoundary: true,
                                    tether: true,
                                    rootBoundary: 'document',
                                    padding: 8,
                                },
                            },
                            {
                                name: 'arrow',
                                enabled: false,

                            },
                        ]}
                    >
                        <Stack elevation={3} direction="column" spacing={2} style={{ backgroundColor: 'white', padding: '20px', borderRadius: '20px', marginTop: '10px', width: '280px' }}>
                            <Typography style={{ color: 'gray', fontSize: '16px', }}>Pipeline</Typography>
                            <RadioGroup
                                value={pipeline}
                                onChange={handleChangePipeline}
                            >
                                {pipelines.map((pipeline) => (
                                    <FormControlLabel value={pipeline.id} label={pipeline.label} control={<Radio />} />
                                ))}
                            </RadioGroup>
                        </Stack>
                    </Popper>
                </div>
            </Stack>

            <Tabs
                value={stage}
                onChange={handleChangeStage}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                centered
                aria-label="scrollable force tabs example"
                style={{ marginBottom: '2rem' }}
            >
                {stages.map((stage) => (
                    <Tab value={stage.id} label={stage.label} />
                ))}
            </Tabs>

            <Grid container spacing={0}>
                {filteredDeals.map((deal) => (
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <DealCard deal={deal} stages={stages} reload={fetchDeals} setLoading={setLoading}/>
                    </Grid>
                ))}
            </Grid>
        </Stack>
    );
};

const DealCard = ({ deal, stages, reload, setLoading }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [selectedStage, setSelectedStage] = useState(deal.stage.id);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const handleClickOpen = () => {
        setSelectedStage(deal.stage.id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setError(null);
    };

    const handleStageChange = (stageId) => {
        setSelectedStage(stageId);
    };

    const handleClickOpenInternal = (event) => {
        event.stopPropagation();
        if (event.target.tagName != 'BUTTON') {
            setLoading(true);
            Api.fetchDeal(deal.id).then((response) => {
                setLoading(false);
                navigate("/app/deals/detailed", { state: { deal: {...response, stage: deal.stage, status: deal.status, pipeline: deal.pipeline, closedate: deal.closedate} } });
            }).catch((error) => {
                console.error("Error fetching deal", error);
                setLoading(false);
            });
        }
    };

    const handleSave = () => {
        setLoading(true);
        setError(null);
        setOpen(false);
        deal.stage = stages.find((stage) => { return stage.id == selectedStage });
        api.CreateDealFromJson({ deal: deal }).then((response) => {
            console.log(response);
            setLoading(false);
            setOpen(false);
            reload();
        }).catch((error) => {
            console.error("Error crteating deal", error);
            setError(t('errorMovingDeal'));
            setLoading(false);
        });
    };

    return (
        <>
            <Paper id="deal-card" onClick={handleClickOpenInternal} style={{ borderRadius: '1.5rem', padding: '1rem', margin: '0.25rem', backgroundColor: '#00000020' }} elevation={3} square={false}>
                <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'start',
                    gap: '10px'
                }}
            >
                <Stack direction="column" spacing={1} style={{width: '100%'}}>
                    <Typography noWrap={true} variant="h5">{deal.name}</Typography>
                    <Stack direction="row" spacing={1}>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        {deal.closedate && <Chip label={dayjs(deal.closedate).format('DD/MM/YYYY')} variant="default" color="default" />}
                        {deal.status && <Chip label={deal.status} variant="default" color="default" />}
                        {deal.amount && <Chip label={deal.amount + ' €'} variant="default" color="default" />}
                    </Stack>
                </Stack>

                <Button onClick={handleClickOpen}
                    style={{
                        backgroundColor: '#76A9AC',
                        fontSize: '0.875rem',
                        fontWeight: '700',
                        lineHeight: '16px',
                        color: '#002657',
                        borderRadius: '25px',
                        padding: '7px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textTransform: 'capitalize',
                        gap: '5px'
                    }} elevation={3}>
                    <DriveFileMoveOutlinedIcon style={{ marginRight: "5px" }} />
                    {t('moveDeal')}
                </Button>
            </div>
        </Paper>
        <Dialog open={open} onClose={handleClose}>
                    <DialogTitle><Stack direction="row" spacing={1}>
                        <DriveFileMoveOutlinedIcon style={{ marginRight: "5px" }} />
                        {t('moveDeal')}
                    </Stack></DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ color: "white" }}>
                            <Typography variant="body1">
                                {t('selectStage')}
                            </Typography>
                        </DialogContentText>
                        {error && <Typography variant="body2" style={{ color: "red" }}>{error}</Typography>}
                        <Stack direction="column" alignItems="center" spacing={1}>
                            <RadioGroup
                                value={selectedStage}
                                onChange={(event) => handleStageChange(event.target.value)}
                            >
                                {stages.map((stage) => (
                                    <>
                                        <FormControlLabel labelPlacement="start" value={stage.id} control={<Radio />} label={stage.label} />
                                        <Divider style={{ color: "white" }} />
                                    </>
                                ))}
                            </RadioGroup>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} style={{ color: "white", fontWeight: '700' }} >
                            {t('cancel')}
                        </Button>
                        <Button onClick={handleSave} style={{ color: "white", fontWeight: '700' }}>
                            {t('save')}
                        </Button>
                    </DialogActions>
                </Dialog>
        </>
    );
}


const DetailedInfoDeal = () => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const deal = state.deal;
    var dealProperties = [
        {icon: <FilterAltOutlined />, label: t('pipeline'), value: `${deal.pipeline.label}`},
        {icon: <DriveFileMoveOutlinedIcon />, label: t('stage'), value: deal.stage.label},
        {icon: <AttachMoneyIcon />, label: t('amount'), value: deal.amount + " €"},
        {icon: <CalendarMonthIcon />, label: t('closedate'), value: `Fecha de cierre: ${dayjs(deal.closedate).format('DD/MM/YYYY')}`},
        {icon: <CalendarMonthIcon />, label: t('createdate'), value: `Fecha de creación: ${dayjs(deal.createdate).format('DD/MM/YYYY')}`},
    ]

    if (deal.contacts.length > 0) {
        var contactProperties = [{
            icon: <PersonIcon />,
            label: t('contact'),
            value: getContactName(deal.contacts[0])
        }];
        contactProperties = [...contactProperties, ...getPropertiesContact(deal.contacts[0], t)];
        dealProperties.push(...contactProperties);
    }

    var jsonObject = { deal: deal };
    if (deal.contacts.length > 0) {
        jsonObject.contact = deal.contacts[0];
        if (deal.contacts[0].company != undefined) {
            jsonObject.company = deal.contacts[0].company;
        }
    }
        
    return <>
        <DetailedInfo title={deal.name} properties={dealProperties} routeBack="/app/deals" audioText="" jsonObject={jsonObject} showRecorder={false} />
    </>
}

export default {
    Deals,
    DealCard,
    DetailedInfoDeal
};