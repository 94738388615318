import styles from '../App.module.css';
import Recorder from '../components/Recorder';
import { useState, useRef, useEffect } from 'react'
import { Alert, Card, Collapse, Grid, Grow, Typography, Zoom, IconButton } from '@mui/material';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import DoneIcon from '@mui/icons-material/Done';
import CheckIcon from '@mui/icons-material/Check';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../Services/Api';
import * as Sentry from "@sentry/browser";
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { Button } from '@mui/material';
import ProcessingComponent from '../designsystem/ProcessingComponent';
import Loading from '../components/Loading';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../Services/i18n';
import HelpIcon from '@mui/icons-material/Help';

function Labia() {
  const { t } = useTranslation();
  const { setHide } = useOutletContext();
  const [user, setUser] = useState(null);
  const { state } = useLocation();
  const { text, example } = state || {};
  const [expand, setExpand] = useState(false);
  const [loading, setLoading] = useState(true);
  const crmUpdated = state != undefined ? state.ok : false
  const failed = state != undefined ? state.fail : false
  const message = state != undefined ? state.message : undefined
  const navigate = useNavigate();
  const randomAction = useRef("");
  var clear = false

  function setClear(val) {
    clear = val
  }

  const [process, setProcess] = useState(false)
  //Errors handling
  const [error, setError] = useState(false)
  //Success handling
  const [success, setSuccess] = useState(crmUpdated && !failed)
  const [processingText, setProcessingText] = useState(t('processingAudio'))
  const [recording, setRecording] = useState(false)
  var lastText = useRef("")
  const [errorAlertMessage, setErrorAlertMessage] = useState(message)
  const [errorAlertOpen, setErrorAlertOpen] = useState(failed);


  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const lang = query.get('lang');
    if (lang) {
      i18n.changeLanguage(lang);
    }
    if (text != undefined) {
      lastText.current = text
      setError(true)
    }
    api.getUserData().then((response) => {
      setUser(response)
      if (response.actions && Object.keys(response.actions).length > 0) {
        randomAction.current = getRandomAction(response)
      } else {
        // Navigate to onboarding if no actions exist
      }
      setLoading(false)
    }).catch((error) => {
      navigate("/")
      setLoading(false)
    })
  }, [text]);

  const sendAudio = (audio) => {
    setClear(false)
    setProcess(true)
    setHide(true)
    api.UploadAudio(audio, onUploadProgress).then((response) => {
      navigate("/app/validate", { state: { text: response.text } })
    }).catch((error) => {
      Sentry.captureException(error);
      setError(true)
      setProcess(false)
      setHide(false)
    })
  }

  const reject = (error) => {
    console.log(error)
    Sentry.captureException(error);
    //json.current = error.originalJson
    setError(true)
    setProcess(false)
  }

  const updateProgress = (progress) => {
    setProcessingText(progress)
  }

  const onUploadProgress = (progressEvent) => {
    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    console.log(percentCompleted)
    if (percentCompleted == 100) {
      setProcessingText(t('processingAudio'))
    } else {
      setProcessingText(t('processingAudio') + " " + percentCompleted + "%")
    }
  }

  const getRandomAction = (user = user) => {
    let randomAction = Object.values(user.actions)[Math.floor(Math.random() * Object.keys(user.actions).length)];
    return t('minutesSaved') + " " + randomAction.minutes + " " + t('minutes') + " " + t('savedIn') + " " + t(randomAction.label.toLowerCase());
  }

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" style={{height: "100vh"}}>
      {example == undefined &&
      <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} justifyContent="center" alignItems="center" style={{position: 'absolute', top: '1rem'  }}>
        <Typography variant="h6" style={{color: "white"}}>¿Quieres aprender a usar Labia?</Typography>
        <Button 
          style={{ 
            backgroundColor: 'white',
            color: 'black'
          }}
          onClick={() => navigate("/app/onboarding")}
        >
         {t('learnToUseLabia')}
        </Button>
      </Stack>
      }
      <Loading loading={loading} />
      
      {success &&
        <Alert style={{
          backgroundColor: "#366E6B", color: "white", position: "absolute", width: "90%", left: "5%", right: "5%",
          bottom: "13%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", textAlign: "center"
        }}
          icon={<CheckIcon style={{ color: "white" }} fontSize="inherit" />}>
          {t('audioSentCorrectly')}
        </Alert>
      }
      {errorAlertOpen &&
        <Alert severity="error"
        style={{
          position: "absolute", width: "90%", left: "5%", right: "5%",
          top: "5%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", textAlign: "center"
        }}>{errorAlertMessage}</Alert>
      } 
      {!process &&
        <Stack direction="column" justifyContent="center" alignItems="center" >
          <Recorder 
            example={example}
            small={true} finished={sendAudio}
            onClear={() => {
              setClear(true)
              setHide(false)
            }}
            onStopRecording={() => {
              setRecording(false)
              setHide(false)
            }}
            onStart={() => {
              setSuccess(false)
              setError(false)
              setRecording(true)
              setHide(true)
            }} />
          {!recording && user &&
            <Stack direction="column" alignItems="center" justifyContent="center" style={{ width: "100%", marginTop: "10%" }}>
              {user.total && user.total != "" &&
                <>
                  <Card onClick={() => setExpand(!expand)} style={{ backgroundColor: "#FFFFFF10", padding: "8px", borderRadius: "10px", width: "90%", maxWidth: "350px",display: "flex", flexDirection: "column"}} elevation={2}>
                      <Stack spacing={1} direction="row" alignItems="center" justifyContent="center">
                        <HourglassEmptyRoundedIcon style={{ color: "white" }} />
                        <Typography variant="body1" style={{ color: "white", fontSize: "0.9375rem", lineHeight: "26px", fontWeight: "400" }}>
                          {randomAction.current}
                        </Typography>
                        {expand ? <KeyboardArrowUpIcon style={{ color: "white" }} /> : <KeyboardArrowDownIcon style={{ color: "white" }} />}
                      </Stack>
                      <Collapse in={expand} orientation="vertical">
                      <Grid container spacing={1} xs={12} sm={12} md={12} lg={12} style={{ marginTop: "10px" }}>
                        {user.actions && Object.keys(user.actions).map((action) => {
                          return (
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                              <Stack spacing={2} direction="row" alignItems="end" justifyContent="end">
                                <Typography style={{ color: "white", fontSize: "14px", fontWeight: "400" }}>
                                  {t(user.actions[action].label)}:
                                </Typography>
                                <Typography style={{ color: "#2E7D32", fontSize: "14px", fontWeight: "700" }}>
                                  {user.actions[action].minutes} {t('min')}
                                </Typography>
                              </Stack>
                            </Grid>
                          )
                        })}
                      </Grid>
                      </Collapse>
                    </Card>
                </>
              }
            </Stack>
            }
        </Stack>
      }

      {process && <ProcessingComponent processingText={processingText} />}

      {error &&
        <Alert style={{
          backgroundColor: "white", color: "red", position: "absolute", width: "90%", left: "5%", right: "5%",
          top: "5%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", textAlign: "center"
        }}
          icon={<FeedbackIcon style={{ color: "red" }} fontSize="inherit" />}
        >
          {t('errorSendingAudio')}
        </Alert>
      }

      {error && lastText.current != "" &&
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setError(false);
            setProcess(true);
            navigate("/app/validate", { state: { text: lastText.current } })
          }}
          style={{ marginTop: "10px" }}
        >
          {t('retry')}
        </Button>
      }
    </Stack>
  );
}

export default Labia;
