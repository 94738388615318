import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography, TextField, Autocomplete, Button, CircularProgress, Alert } from '@mui/material';
import Chip from '@mui/material/Chip';

import Api from '../Services/Api';

const Properties = () => {
    const allProperties = ['contact', 'company', 'deal', 'task', 'meeting', 'note', 'mail', 'call'];
    const { t } = useTranslation();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [objectType, setObjectType] = useState(allProperties[0]);
    const [properties, setProperties] = useState([]);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        Api.getUserData().then((response) => {
            setUser(response);
            if (response.role == undefined || response.role != 'admin') {
                navigate('/app');
            }
            onChangeObjectType(objectType);
        });
    }, []);

    const handleUpdateProperties = () => {
        setLoading(true);
        Api.updateProperties(objectType, { properties: selectedProperties }).then((response) => {
            setLoading(false);
            setSuccess(true);
        });
    }

    const onChangeObjectType = (newValue) => {
        setObjectType(newValue);
        setSelectedProperties([]);
        setLoading(true);
        Api.fetchProperties(newValue, true, true).then((responseAll) => {
            setProperties(responseAll);
            Api.fetchDomainProperties().then((response) => {
                if (response.properties[newValue]) {
                    setSelectedProperties(response.properties[newValue]);
                }
                setLoading(false);
            }).catch((error) => {
                setLoading(false);
            });
        }).catch((error) => {
            setLoading(false);
        });
    }


    return <Stack direction="column" spacing={6} alignItems="center" style={{ padding: "1rem" }}>
        <Typography variant="h5" style={{ color: "white" }}>{t('properties')}</Typography>
        {success && <Alert severity="success">{t('propertiesUpdated')}</Alert>}
        {loading && <CircularProgress />}
        {!loading &&
            <Stack direction="column" spacing={2} alignItems="left" style={{ width: "100%" }}>
                <Typography variant="h6" style={{ color: "white" }}>{t('propertyToUpdate')}</Typography>
                <Autocomplete
                    options={allProperties}
                    value={objectType}
                    onChange={(event, newValue) => onChangeObjectType(newValue)}
                    getOptionLabel={(option) => t(option)}
                    renderInput={(params) => <TextField {...params} label={t('properties')} />}
                />
                <Typography variant="h6" style={{ color: "white" }}>{t('selectProperty')}</Typography>
                <Autocomplete
                    multiple
                    disablePortal
                    id="properties-select"
                    style={{ width: "100%" }}
                    value={properties.filter(prop => selectedProperties.includes(prop.name))}
                    onChange={(event, newValue) => setSelectedProperties(newValue.map(prop => prop.name))}
                    options={properties}
                    autoSelect={true}
                    renderInput={(params) => (
                        <TextField {...params} label={t('select_properties')} />
                    )}
                    groupBy={(option) => option.label}
                    renderOption={(props, option) => (
                        <Stack spacing={1} component="li" {...props}>
                            <Typography variant="subtitle1" style={{ width: "100%", textAlign: "left" }}>
                                {option.description || option.label}
                            </Typography>
                        </Stack>
                    )}
                />
                <Button variant="contained" color="primary" onClick={handleUpdateProperties}>
                    {t('updateProperties')}
                </Button>
            </Stack>
        }
    </Stack>
}

export default Properties;