import React, { useEffect, useState, useRef } from 'react';
import { Button, Grid, Paper, Stack, Typography } from '@mui/material';
import ContactPageOutlined from '@mui/icons-material/ContactPageOutlined';
import WorkOutlineOutlined from '@mui/icons-material/WorkOutlineOutlined';
import FilerAltOutlined from '@mui/icons-material/FilterAltOutlined';
import PersonOutlineOutlined from '@mui/icons-material/PersonOutlineOutlined';
import TaskAltOutlined from '@mui/icons-material/TaskAltOutlined';
import Settings from '@mui/icons-material/Settings';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import '../index.css'
import Api from '../Services/Api';
import { useTranslation } from 'react-i18next';
const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [size, setSize] = useState(0);
  const [hide, setHide] = useState(false);
  const { t } = useTranslation();
  const [logged, setLogged] = useState(false);
  const sheetRef = useRef(null);
  const [user, setUser] = useState(null);
  const bottomNavigationItems = [
    { label: t('contacts'), icon: <ContactPageOutlined style={{width: "50px", height: "50px"}} />, path: '/app/contacts' },
    { label: t('companies'), icon: <WorkOutlineOutlined style={{width: "50px", height: "50px"}} />, path: '/app/companies' },
    { label: t('deals'), icon: <FilerAltOutlined style={{width: "50px", height: "50px"}} />, path: '/app/deals' },
    { label: t('account'), icon: <PersonOutlineOutlined style={{width: "50px", height: "50px"}} />, path: '/account' },
    { label: t('tasks'), icon: <TaskAltOutlined style={{width: "50px", height: "50px"}} />, path: '/app/tasks' },
    { label: t('properties'), icon: <Settings style={{width: "50px", height: "50px"}} />, path: '/app/properties', role: 'admin' },
  ]

  useEffect(() => {
    window.scrollTo(0, 0);
    Api.getUserData().then((response) => {
      setLogged(true)
      setUser(response)
    }).catch((error) => {
      setLogged(false)
      navigate("/")
    })
  }, [pathname])


  return (
    <div data-body-scroll-lock-ignore="true">
      <div style={{ padding: `0 0 ${size}px 0`, zIndex: 1000}} >
        <Outlet context={{setHide: setHide, user: user}}/>
      </div>
      {logged && !hide && <BottomSheet
        open
        scrollLocking
        ref={sheetRef}
        blocking={false}
        expandOnContentDrag={true}
        snapPoints={({ maxHeight }) => {
          var size = maxHeight * 0.07
          var paddingSize = size * 1.1
          const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
          if (!isMobile) {
            size = 70
            paddingSize = 80
          }
          setSize(paddingSize)
          return [size, maxHeight * 0.9]
        }
      }
        header={<Typography variant="h6" style={{color: 'black', textAlign: 'left'}} >Menu</Typography>}
      >
        <Grid container style={{padding: "25px"}}>
          {bottomNavigationItems.filter(item => item.role == user.role || item.role == undefined).map((item, index) => (
            <Grid item xs={6} padding={2}>
              <Button
                style={{
                  backgroundColor: '#00000090',
                  width: '100%',
                  height: '100%',
                  color: 'white',
                  borderRadius: '20px',
                }}
                onClick={() => {
                  navigate(item.path)
                  sheetRef.current.snapTo(({ snapPoints }) =>
                    Math.min(...snapPoints)
                  )
                }}>
                <Stack 
                  spacing={2}
                  padding={2}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  {item.icon}
                  <Typography variant="button" style={{textTransform: "capitalize", fontSize: "16px"}}> {item.label} </Typography>
                </Stack>
              </Button>
            </Grid>
          ))}
          <Grid item xs={12} padding={1}>
          <Button
                style={{
                  width: '100%',
                  height: '100%',
                  color: 'white',
                  borderRadius: '10px',
                }}
                onClick={() => {
                  sheetRef.current.snapTo(({ snapPoints }) =>
                    Math.min(...snapPoints)
                  )
                  navigate("/app")
                  }}>
                <Stack 
                  spacing={2}
                  padding={2}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <img src="/labia_logo.png" alt="Labia" style={{ width: '62px', height: '62px' }} />
                  <Typography variant="h6" style={{textTransform: "capitalize"}}>{t('record')}</Typography>
                </Stack>
              </Button>
            
          </Grid>
        </Grid>


      </BottomSheet>}
      
    </div>
  );
};
 
export default Layout;