import React, { useState, useEffect, useRef } from 'react';
import { Stack, Typography, Grid, Chip, Alert, IconButton, Paper } from '@mui/material';
import api from '../Services/Api';
import { useNavigate, useLocation } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import Loading from '../components/Loading';
import SearchComponent from '../components/SearchComponent';
import WebIcon from '@mui/icons-material/Web';
import PhoneOutlined from '@mui/icons-material/PhoneOutlined';
import PlaceOutlined from '@mui/icons-material/PlaceOutlined';
import ArrowBack from '@mui/icons-material/ArrowBack';
import DetailedInfo from './DetailedInfo';
import { useTranslation } from 'react-i18next';
const Companies = () => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const [loading, setLoading] = useState(true);
    const crmUpdated = state != undefined ? state.ok : false
    const [companies, setCompanies] = useState([]);
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [createdate, setCreatedate] = useState("DESCENDING");

    const previousController = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        api.fetchCompanies({ createdate: createdate, noCache: true }).then((response) => {
            setCompanies(response.options || []);
            setFilteredCompanies(response.options || []);
            setLoading(false);
        }).catch((error) => {
            console.error("Error fetching companies", error);
            setLoading(false);
        });
    }, []);

    const handleSearchChange = (searchTerm) => {
        if (previousController.current) {
            previousController.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;
        setLoading(true);
        api.fetchCompanies({
            value: searchTerm.toLowerCase(),
            createdate: createdate,
            after: 0,
            signal: signal
        }).then((response) => {
            setFilteredCompanies(response.options || []);
            setLoading(false);
        }).catch((error) => {
            console.error("Error fetching companies", error);
            setLoading(false);
        });
    };

    const handleAudioSent = (text, company) => {
        navigate("/app/validate", { state: { text: text, json: { company: company }, routeBack: "/app/companies" } });
    }

    const handleSortChange = (searchTerm, createdate) => {
        setLoading(true);
        setCreatedate(createdate);
        api.fetchCompanies({
            value: searchTerm.toLowerCase(),
            after: 0,
            createdate: createdate
        }).then((response) => {
            setFilteredCompanies(response.options || []);
            setLoading(false);
        }).catch((error) => {
            console.error("Error fetching contacts", error);
            setLoading(false);
        });
    }

    return (
        <Stack style={{ padding: '1rem', width: '100%', margin: 'auto' }}>
            <Stack direction="row" spacing={2} style={{ marginBottom: '1rem' }} alignItems="center">
                <IconButton style={{ color: 'white' }} onClick={() => {
                    navigate("/app");
                }}>
                    <ArrowBack />
                </IconButton>
                <Typography variant="h5" style={{ color: 'white', textAlign: 'left', width: '100%', verticalAlign: 'middle' }} >{t('companies')}</Typography>
            </Stack>
            <Loading loading={loading} />
            {crmUpdated &&
                <Alert style={{
                    backgroundColor: "#366E6B", color: "white", position: "absolute", width: "90%", left: "5%", right: "5%",
                    top: "5%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", textAlign: "center"
                }}
                    icon={<CheckIcon style={{ color: "white" }} fontSize="inherit" />}>
                    {t('crmUpdated')}
                </Alert>
            }
            <SearchComponent createdate={createdate} handleSortChange={handleSortChange} handleSearchChange={handleSearchChange} />

            <Grid container spacing={1} style={{ marginTop: '10px' }}>
                {filteredCompanies.map((company) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <CompanyCard company={company} />
                    </Grid>
                ))}
            </Grid>

        </Stack>
    );
};

const CompanyCard = ({ company }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return <Paper style={{ borderRadius: '1.5rem', padding: '1rem', margin: '0.25rem', backgroundColor: '#00000020' }} elevation={3} square={false}>
        <div onClick={() => { navigate("/app/companies/detailed", { state: { company: company } }) }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', gap: '10px', width: '100%' }}>
            <Typography style={{ fontSize: '16px', whiteSpace: 'normal !important' }}>{company.name ?? "--"}</Typography>
            <Chip icon={<WebIcon />} noWrap={true} label={company.domain ?? t('domain')} variant="outlined" color="chipInfo" />
            <Chip icon={<PhoneOutlined />} noWrap={true} label={company.phone ?? t('phone')} variant="outlined" color="default" />
            <Chip icon={<PlaceOutlined />} noWrap={true} label={getAddressCompany(company, t)} variant="outlined" color="default" />
        </div>
    </Paper>
}

const DetailedInfoCompany = () => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const company = state.company;
    var companyProperties = [
        {icon: <WebIcon />, label: t('domain'), value: company.domain},
        {icon: <PhoneOutlined />, label: t('phone'), value: company.phone},
        {icon: <PlaceOutlined />, label: t('address'), value: getAddressCompany(company, t)}
    ]
    var jsonObject = { company: company };
    return <>
        <DetailedInfo title={company.name} properties={companyProperties} routeBack="/app/companies" audioText={t('audioCompanyText')} jsonObject={jsonObject} />
    </>
}

const getAddressCompany = (company, t) => {
    var address = [];
    if (company.addres) address.push(company.addres);
    if (company.city) address.push(company.city);
    if (company.country) address.push(company.country);
    if (address.length === 0) return t('address');
    return address.join(", ");
}

export default {
    Companies,
    CompanyCard,
    getAddressCompany,
    DetailedInfoCompany
}; 