import ArrowBack from "@mui/icons-material/ArrowBack";
import { Card, IconButton, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Recorder from "../components/Recorder";
import ProcessingComponent from "../designsystem/ProcessingComponent";
import Api from "../Services/Api";
import { useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from 'react-i18next';

const DetailedInfo = ({ title, properties, routeBack, audioText = "Pulsa para grabar", jsonObject = {}, showRecorder = true, customView = null }) => {
    const { t } = useTranslation();
    const [processing, setProcessing] = useState(false);
    const navigate = useNavigate();

    const onUploadProgress = (progressEvent) => {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log(percentCompleted);
        if (percentCompleted === 100) {
        } else {
        }
    };

    const finished = (audio) => {
        setProcessing(true);
        Api.UploadAudio(audio, onUploadProgress).then((response) => {
            handleAudioSent(response.text);
            setProcessing(false);
        }).catch((error) => {
            Sentry.captureException(error);
        });
    };

    const handleAudioSent = (text) => {
        navigate("/app/validate", { state: { text: text, json: jsonObject, routeBack: routeBack } });
    }

    return <Stack direction="column" spacing={6} alignItems="center" style={{ padding: "1rem" }}>
        <Stack direction="row" spacing={2} alignItems="center" style={{ width: "100%" }}>
            <IconButton onClick={() => navigate(routeBack)}>
                <ArrowBack />
            </IconButton>
            <Typography variant="h5" style={{ color: "white" }}>{title}</Typography>
        </Stack>

        {showRecorder && <Card elevation={6} style={{ backgroundColor: "#FFFFFF10", borderRadius: "10px", width: "100%" }}>
            <Stack direction="row" spacing={2} style={{ paddingTop: "100px", paddingBottom: "65px" }} alignItems="center" justifyContent="center">
                {!processing && <Recorder finished={finished} onClear={() => { }} onStart={() => { }} small={true} audioText={audioText} />}
                {processing && <ProcessingComponent processingText={t('processingAudio')} />}
            </Stack>
        </Card>
        }
        {customView ? customView : <></>}
        <Card elevation={6} style={{ backgroundColor: "#FFFFFF10", borderRadius: "10px", width: "100%" }}>
            <Stack direction="column" spacing={2} style={{ padding: "25px", backgroundColor: "#FFFFFF20", borderRadius: "10px", width: "100%" }} alignItems="start" justifyContent="start">
        {properties.map((property, index) => (
                    <Stack direction="row" spacing={2} style={{ color: "white" }}>
                        {property.icon}
                        <Typography variant="body1" style={{ color: "white" }}>{property.value ?? property.label}</Typography>
                    </Stack>
                ))}
            </Stack>
        </Card>
    </Stack>
}

export default DetailedInfo;